import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';

const PasswordUpdateSuccessful = ({ onClose }) => {
  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, 2000);
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
        <Typography variant="h4">Password Update Successfully</Typography>
      </Grid>
    </Grid>
  );
};

export default PasswordUpdateSuccessful;
