import {
  Alert,
  Avatar,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvestment } from 'store/investments/actions';
import { getWalletDetails } from 'store/wallet/actions';
import { createWithdrawalReq, getReturnData } from 'store/withdraw/actions';
import * as Yup from 'yup';
import uploadIcon from 'assets/images/users/uploadIcon.png';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import SimpleModal from 'ui-component/modals/SimpleModal';
import OTPVerification from './OTPVerification';
import api from 'utils/api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const CapitalWithdrawalForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const past90thdate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
  const [qrImage, setQrImage] = useState(null);
  const [qrImageName, setQrImageName] = useState(null);
  const [withdrawOption, setWithdrawOption] = useState('');
  const [walletAmt, setWalletAmt] = useState(0);
  const [limit, setLimit] = useState(20);
  const [withdrawMethode, setWithdrawMethode] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cnfwalletAddress, setcnfWalletAddress] = useState(null);
  const [mycdn, setMycdn] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [bodyData, setBodyData] = useState({});
  const [responseData, setResponseData] = useState(null);

  const [servicePerc, setServicePerc] = useState(0);
  const [withdrawPercIn90Days, setWithdrawPercIn90Days] = useState(0);
  const [minWithdrawAmtWallet, setMinWithdrawAmtWallet] = useState(0);
  const [isUnder90days, setIsUnder90days] = useState(false);
  const [mainAmount, setMainAmount] = useState(0);
  const [walletAvailableAmount, setWalletAvailableAmount] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [recievable, setRecievable] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [plusWithdrawalErr, setPlusWithdrawalErr] = useState(false);
  const [modalData, setModalData] = useState({
    title: '',
    content: '',
    navigateUrl: ''
  });
  const handleCloseModal = () => {
    setOpenModal(false);
    navigate('/user/withdraw');
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const handleCloseOtp = () => {
    setOtpModalOpen(false);
  };
  const handleOpenOtp = () => {
    setOtpModalOpen(true);
  };

  const { user } = useSelector((state) => state?.auth);
  useEffect(() => {
    dispatch(getInvestment({ strUserId: user?._id, chrStatus: 'N' }));
    dispatch(getReturnData());
    dispatch(getWalletDetails({ _id: user?._id }));
  }, []);
  const { objSettings } = useSelector((state) => state?.withdraw);
  const { myInvestments } = useSelector((state) => state?.investments);
  const { walletDetails } = useSelector((state) => state?.wallet);
  useEffect(() => {
    setServicePerc(objSettings?.ALL_WITHDRAW_PERCENTAGE?.intPercentage);
    setWithdrawPercIn90Days(objSettings?.CAPITAL_WITHDRAW_PERCENTAGE_IN_6_MONTH?.intPercentage);
    setMinWithdrawAmtWallet(objSettings?.MIN_WITHDRAW_AMT_FOR_WALLET?.intPercentage);
  }, [objSettings]);
  useEffect(() => {
    const referalTotal = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'REFERAL');
    const dailyProfit = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'PROFIT');
    const withdrawalAmount = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'RETURNS');
    const salaryCredits = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'SALARY_CREDIT');
    setWalletAvailableAmount(
      (referalTotal?.intAmt ? referalTotal?.intAmt : 0) +
        (dailyProfit?.intAmt ? dailyProfit?.intAmt : 0) +
        (withdrawalAmount?.intAmt ? withdrawalAmount?.intAmt : 0) +
        (salaryCredits?.intAmt ? salaryCredits?.intAmt : 0)
    );
  }, [walletDetails]);
  console.log('minWithdrawAmtWallet', minWithdrawAmtWallet);
  const formik = useFormik({
    initialValues: {
      strReturnAddress: '',
      strCnfReturnAddress: '',
      strType: '',
      strPackageId: '',
      intTotalDeductionAmt: '',
      intServiceFeeAmt: '',
      intPayableAmt: '',
      intCapitalreturnWithin90DaysAmt: '',
      strReturnAddressBase64: '',
      strInvestmentId: '',
      strTransactionPassword: '',
      walletAccount: ''
    },
    validationSchema: Yup.object().shape({
      strReturnAddress: Yup.string().max(255).required('Wallet address is required'),
      strCnfReturnAddress: Yup.string()
        .required('confirm wallet address is required.')
        .oneOf([Yup.ref('strReturnAddress'), null], 'Address must match'),
      intTotalDeductionAmt:
        withdrawOption === 'wallet'
          ? Yup.number()
              .max(walletAvailableAmount, `Maximum available amount is ${walletAvailableAmount}`)
              .moreThan(0, 'Amount must be greater than zero')
              .min(minWithdrawAmtWallet, `Minimum amount is ${minWithdrawAmtWallet}`)
              .required('Amount is required')
          : Yup.number().moreThan(0, 'Amount must be greater than zero').required('Amount is required'),
      strInvestmentId: withdrawOption === 'wallet' ? null : Yup.string().required('Plan is required'),
      strTransactionPassword: Yup.string().max(255).required('Transaction password is required'),
      strReturnAddressBase64: Yup.string().required('This is required'),
      walletAccount: Yup.string().required('Wallet network is required')
    }),
    onSubmit: async (values) => {
      if (plusWithdrawalErr) {
        return;
      }
      if (walletAvailableAmount < 0) {
        setErrMsg('Wallet Amount is Minus Value');
      }
      if (values.strReturnAddressBase64) {
        values.strReturnAddressBase64 = values.strReturnAddressBase64.split(',')[1];
      }
      let body = {
        strReturnAddress: values.strCnfReturnAddress,
        intTotalDeductionAmt: values.intTotalDeductionAmt,
        strReturnAddressBase64: values.strReturnAddressBase64,
        strType: values.strType,
        intServiceFeeAmt: values.intServiceFeeAmt,
        intPayableAmt: values.intPayableAmt,
        strTransactionPassword: values.strTransactionPassword
      };
      if (withdrawOption === 'capital') {
        body.strPackageId = values.strPackageId;
        body.strInvestmentId = values.strInvestmentId;
        body.intCapitalreturnWithin90DaysAmt = values.intCapitalreturnWithin90DaysAmt;
      }
      setIsLoading(true);
      try {
        setBodyData(body);
        const res = await dispatch(createWithdrawalReq(body));
        console.log('Respponse', res);
        setResponseData(res);
        setModalData({
          title: 'Withdrawal proccess successfull',
          content: 'Withdrawal proccess usually takes 2 - 6 hours',
          navigateUrl: '/user/withdraw'
        });
        // handleOpenModal();
        handleOpenOtp();
        // navigate('/user/withdraw');
      } catch (error) {
        console.log('error in c', error);
        if (error?.response?.data?.strMessage === ' WALLET  RETURN REQUEST') {
          setModalData({ title: 'Unsuccessful', content: 'Already one withdrawal request on pending', navigateUrl: '/user/withdraw' });
          handleOpenModal();
        }
        if (error?.response?.data?.strMessage === 'Transaction Password is not correct') {
          setModalData({ title: 'Unsuccessful', content: 'Transaction Password is not correct', navigateUrl: '/user/withdraw' });
          handleOpenModal();
        }
      }
      setIsLoading(false);
    }
  });
  const { values, touched, errors, handleSubmit, setFieldValue, handleChange } = formik;
  useEffect(() => {
    if (mainAmount > 0) {
      if (withdrawOption === 'wallet') {
        const serviceAmount = mainAmount * (servicePerc / 100);
        const total = mainAmount - serviceAmount;
        setRecievable(total);
        setFieldValue('intPayableAmt', total);
        setFieldValue('intServiceFeeAmt', serviceAmount);
        setFieldValue('intCapitalreturnWithin90DaysAmt', 0);
      }
      if (withdrawOption === 'capital') {
        const serviceAmount = mainAmount * (servicePerc / 100);
        let under90daysFee = 0;
        if (isUnder90days) {
          under90daysFee = mainAmount * (withdrawPercIn90Days / 100);
        }
        const total = mainAmount - serviceAmount - under90daysFee;
        setRecievable(total);

        setFieldValue('intPayableAmt', total);
        setFieldValue('intServiceFeeAmt', serviceAmount);
        setFieldValue('intCapitalreturnWithin90DaysAmt', under90daysFee);
      }
      setFieldValue('intTotalDeductionAmt', mainAmount);
    }
  }, [mainAmount]);
  //functions
  const handleChangeWithdrawOption = (e) => {
    const option = e.target.value;
    setSelectedPackage('');
    setFieldValue('intPayableAmt', '');
    setFieldValue('intServiceFeeAmt', '');
    setFieldValue('intCapitalreturnWithin90DaysAmt', '');
    setFieldValue('intTotalDeductionAmt', '');
    setFieldValue('strPackageId', '');
    setFieldValue('strInvestmentId', '');
    setFieldValue('strType', option);
    setMainAmount(0);
    setRecievable(0);
    setWithdrawOption(e.target.value);
  };
  function isDifferenceGreaterThanAYear(date) {
    const today = new Date();

    const differenceInMilliseconds = Math.abs(today - date);

    const millisecondsInYear = 1000 * 60 * 60 * 24 * 365;
    const differenceInYears = differenceInMilliseconds / millisecondsInYear;

    return differenceInYears > 1;
  }
  const handleChangePlans = (e) => {
    const createTime = new Date(e.target.value.strCreatedTime);
    setMainAmount(e.target.value.intCapitalAmt);
    if (createTime > past90thdate) {
      setIsUnder90days(true);
    } else {
      setIsUnder90days(false);
    }
    setSelectedPackage(e.target.value);
    setFieldValue('strInvestmentId', e.target.value._id);

    setFieldValue('strPackageId', e.target.value.objPackage[0]._id);
    const isGreaterthanOneyear = isDifferenceGreaterThanAYear(createTime);
    if (!isGreaterthanOneyear && e.target.value?.investmentLockType && e.target.value?.investmentLockType === 'active') {
      setPlusWithdrawalErr(true);
    } else {
      setPlusWithdrawalErr(false);
    }
  };
  const handleChangeAmount = (amount) => {
    setMainAmount(parseInt(amount));
  };
  const handleClickQRRemove = () => {
    setQrImage(null);
    setQrImageName(null);
    setFieldValue('strReturnAddressBase64', null);
  };
  const handleChangeQR = (e) => {
    setQrImageName(e.target.files[0].name);

    const reader = new FileReader();
    reader.onload = (event) => {
      setFieldValue('strReturnAddressBase64', event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    setQrImage(URL.createObjectURL(e.target.files[0]));
  };
  const handleClickCancel = () => {
    navigate(-1);
  };
  console.log('myInvestments', myInvestments);
  const verifyOTP = async (otpData) => {
    const body = {
      _id: responseData?._id ? responseData?._id : '',
      strOTP: otpData
    };
    try {
      const { data } = await api.post('/verify_otp', body);
      setModalData({
        title: 'Withdrawal proccess successfull',
        content: 'Withdrawal proccess usually takes 48 hours',
        navigateUrl: '/user/withdraw'
      });
      handleOpenModal();
    } catch (error) {
      setModalData({ title: 'Unsuccessful', content: 'Something went wrong.Try again', navigateUrl: '/user/capitalwithdrawal' });
      handleOpenModal();
    }
  };
  const resendOTP = async () => {
    const res = await dispatch(createWithdrawalReq(bodyData));
    console.log('Respponse', res);
    setResponseData(res);
  };
  return (
    <Grid container>
      {isLoading ? (
        <Grid item xs={12} md={12} display={'flex'} sx={{ height: '100vh' }} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Card sx={{ padding: '16px' }}>
              <Typography variant="h3">Withdrawal Request</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card sx={{ padding: '16px' }}>
              <Grid container>
                <Grid item xs={0} md={2}></Grid>
                <Grid item xs={12} md={8}>
                  <FormikProvider value={formik}>
                    <Form onSubmit={handleSubmit}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 2 }}>
                        <Typography variant="h6" fontWeight="smaller" sx={{ mt: 2 }}>
                          Withdraw from
                        </Typography>
                        <Select
                          value={withdrawOption}
                          // disabled
                          onChange={(e) => handleChangeWithdrawOption(e)}
                          //   onChange={(e) => {
                          //     const selectedValue = e.target.value;
                          //     setWithdrawOption(selectedValue);
                          //     if (selectedValue === 'wallet') {
                          //       setLimit(walletAmt);
                          //     } else {
                          //       if (data && data.deposits) {
                          //         const totalDepositAmount = data.deposits.reduce((total, deposit) => total + parseFloat(deposit.amount), 0);
                          //         setLimit(totalDepositAmount);
                          //         setAmount(totalDepositAmount);
                          //       }
                          //     }
                          //   }}

                          label="Withdraw from"
                          name="withdrawFrom"
                        >
                          {/* <MenuItem value="wallet">Wallet</MenuItem> */}
                          <MenuItem value="capital">Capital</MenuItem>
                        </Select>
                      </FormControl>

                      {withdrawOption === 'capital' ? (
                        <FormControl fullWidth variant="outlined" sx={{ my: 2 }}>
                          <Typography variant="h6" fontWeight="smaller" sx={{ mt: 2 }}>
                            Select Deposited Plan
                          </Typography>
                          <Select
                            name="withdrawPlan"
                            // onChange={(e) => {
                            //   const selectedDepositId = e.target.value;
                            //   setSelectedDepositId(selectedDepositId);

                            //   const selectedDeposit = data?.deposits.find((deposit) => deposit.id === selectedDepositId);

                            //   if (selectedDeposit) {
                            //     setLimit(parseFloat(selectedDeposit.amount));
                            //     setAmount(parseFloat(selectedDeposit.amount));
                            //     setWithdrawPlanTime(new Date(selectedDeposit.createdAt));
                            //   }
                            // }}
                            value={selectedPackage}
                            onChange={(e) => handleChangePlans(e)}
                          >
                            {myInvestments?.map((deposit) => (
                              <MenuItem key={deposit._id} value={deposit}>
                                {`${deposit?.objPackage[0]?.strName}  - $${deposit?.intCapitalAmt}`}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.strInvestmentId && (
                            <FormHelperText error id="standard-weight-helper-text-password-register">
                              {errors.strInvestmentId}
                            </FormHelperText>
                          )}
                        </FormControl>
                      ) : null}
                      {withdrawOption === 'capital' && (
                        <Typography variant="h6" sx={{ mt: 2 }}>
                          Withdrawal Service Fee Amount :{mainAmount ? mainAmount * (servicePerc / 100) : 0}
                        </Typography>
                      )}

                      {isUnder90days && withdrawOption === 'capital' && (
                        <Typography variant="h6" sx={{ mt: 2 }}>
                          Withdrawal under 90 days fee amount :{mainAmount ? mainAmount * (withdrawPercIn90Days / 100) : 0}
                        </Typography>
                      )}
                      <Typography variant="h6" sx={{ mt: 2 }}></Typography>
                      {/* <FormControl fullWidth variant="outlined" sx={{ my: 2 }}>
                      <Typography variant="h6" fontWeight="smaller" sx={{ mt: 2,    }}>
                        Withdraw Methods
                      </Typography>
                      <Select
                        value={withdrawMethode}
                        onChange={(e) => setWithdrawMethode(e.target.value)}
                        label="Withdraw method"
                        labelId="withdraw-method-label" // Add this line
                        name="withdrawMethode"
                      >
                        <MenuItem value="Trc20">Trc 20</MenuItem>
                        <MenuItem value="Roe">Roe</MenuItem>
                      </Select>
                    </FormControl> */}
                      {withdrawOption === 'wallet' && (
                        <Typography variant="h6" sx={{ mt: 2 }}>
                          Minimum withdrawal amount :{minWithdrawAmtWallet}
                        </Typography>
                      )}
                      {withdrawOption === 'wallet' && (
                        <Typography variant="h6" sx={{ mt: 2 }}>
                          Available wallet amount :{walletAvailableAmount}
                        </Typography>
                      )}
                      <Typography variant="h6" fontWeight="smaller" sx={{ mt: 2 }}>
                        Enter Amount
                      </Typography>
                      {withdrawOption === 'wallet' ? (
                        <TextField
                          value={mainAmount}
                          sx={{
                            width: '100%',
                            '& input[type=number]': {
                              '-moz-appearance': 'textfield' /* Firefox */
                            },
                            '& input[type=number]::-webkit-inner-spin-button': {
                              '-webkit-appearance': 'none',
                              margin: 0
                            }
                          }}
                          type="number"
                          name="amount"
                          // max={limit} // Set the maximum value (use 'limit' variable if it's appropriate)
                          onChange={(e) => handleChangeAmount(e.target.value)}
                          error={Boolean(touched.intTotalDeductionAmt && errors.intTotalDeductionAmt)}
                          helperText={touched.intTotalDeductionAmt && errors.intTotalDeductionAmt}
                        />
                      ) : (
                        <TextField
                          disabled
                          value={mainAmount}
                          sx={{
                            width: '100%',
                            '& input[type=number]': {
                              '-moz-appearance': 'textfield' /* Firefox */
                            },
                            '& input[type=number]::-webkit-inner-spin-button': {
                              '-webkit-appearance': 'none',
                              margin: 0
                            }
                          }}
                          type="number"
                          name="amount"
                          error={Boolean(touched.intTotalDeductionAmt && errors.intTotalDeductionAmt)}
                          helperText={touched.intTotalDeductionAmt && errors.intTotalDeductionAmt}

                          // max={limit} // Set the maximum value (use 'limit' variable if it's appropriate)
                        />
                      )}

                      {withdrawOption === 'wallet' && (
                        <Typography variant="h6" sx={{ mt: 2 }}>
                          Withdrawal Service Fee Amount :{mainAmount ? mainAmount * (servicePerc / 100) : 0}
                        </Typography>
                      )}
                      <Typography variant="h6" fontWeight="smaller" sx={{ mt: 2 }}>
                        Actual Receivable Amount
                      </Typography>

                      <TextField
                        value={recievable}
                        readOnly
                        sx={{
                          width: '100%',
                          '& input[type=number]': {
                            '-moz-appearance': 'textfield' /* Firefox */
                          },
                          '& input[type=number]::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0
                          }
                        }}
                        type="number"
                        name="receivable"
                        min={0} // Set the minimum value here
                        max={limit} // Set the maximum value (use 'limit' variable if it's appropriate)
                      />

                      <Typography variant="h6" fontWeight="smaller" sx={{ mt: 2 }}>
                        Wallet Address
                      </Typography>

                      <TextField
                        fullWidth
                        variant="outlined"
                        name="strReturnAddress"
                        //   value={walletAddress}
                        onChange={handleChange}
                        sx={{ my: 2 }}
                        error={Boolean(touched.strReturnAddress && errors.strReturnAddress)}
                        helperText={touched?.strReturnAddress && errors?.strReturnAddress}
                      />

                      <Typography variant="h6" fontWeight="smaller" sx={{ mt: 2 }}>
                        Confirm Wallet Address
                      </Typography>

                      <TextField
                        fullWidth
                        variant="outlined"
                        name="strCnfReturnAddress"
                        //   value={cnfwalletAddress}
                        onChange={handleChange}
                        sx={{ my: 2 }}
                        error={Boolean(touched.strCnfReturnAddress && errors.strCnfReturnAddress)}
                        helperText={touched?.strCnfReturnAddress && errors?.strCnfReturnAddress}
                      />

                      <Typography variant="h6" fontWeight="smaller" sx={{ mt: 2 }}>
                        Transaction Password
                      </Typography>

                      <TextField
                        fullWidth
                        variant="outlined"
                        type="password"
                        sx={{ my: 2 }}
                        name="strTransactionPassword"
                        //   value={cnfwalletAddress}
                        onChange={handleChange}
                        error={Boolean(touched.strTransactionPassword && errors.strTransactionPassword)}
                        helperText={touched?.strTransactionPassword && errors?.strTransactionPassword}
                      />
                      <FormControl fullWidth sx={{ my: 2 }}>
                        <InputLabel>Network</InputLabel>
                        <Select
                          value={values.walletAccount}
                          name="walletAccount"
                          onChange={handleChange}
                          // onChange={(e) => setFieldValue('walletAccount', e.target.value)}
                          fullWidth
                          label="Network"
                        >
                          <MenuItem value="USDT-TRC20">USDT-TRC20</MenuItem>
                          <MenuItem value="USDT-BEP20">USDT-BEP20</MenuItem>
                        </Select>
                        {errors.walletAccount && (
                          <FormHelperText error id="standard-weight-helper-text-password-register">
                            {errors.walletAccount}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <Typography variant="h6" fontWeight="smaller" sx={{ mt: 2 }}>
                        Upload QR
                      </Typography>
                      <Grid container mt={2}>
                        <Grid item xs={12} md={12}>
                          <Grid container>
                            <Grid item xs={3} md={3}>
                              <Box sx={{ height: '75px', width: '75px' }}>
                                <Avatar variant="square" sx={{ width: '100%', height: 75 }}>
                                  {/* <UploadFileIcon sx={{ height: 100 }} /> */}
                                  <img style={{ height: 75 }} src={qrImage ? qrImage : uploadIcon} alt="remy sharp" />
                                </Avatar>
                              </Box>
                            </Grid>
                            <Grid item xs={9} md={9}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                  {qrImageName ? <Typography>{qrImageName}</Typography> : <Typography>Choose Image</Typography>}
                                </Grid>

                                <Grid item xs={12} md={12}>
                                  {qrImageName ? (
                                    <Button fullWidth variant="contained" onClick={handleClickQRRemove}>
                                      Remove
                                    </Button>
                                  ) : (
                                    <Button fullWidth variant="contained" component="label">
                                      Upload
                                      <input onChange={handleChangeQR} hidden accept="image/*" type="file" />
                                    </Button>
                                  )}
                                  {errors.strReturnAddressBase64 && (
                                    <FormHelperText error id="standard-weight-helper-text-password-register">
                                      {errors.strReturnAddressBase64}
                                    </FormHelperText>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* <Grid item xs={12} md={12} mt={2}>
                          <Alert severity="error" variant="filled">
                            {errMsg}
                          </Alert>
                        </Grid> */}

                      {plusWithdrawalErr && (
                        <Grid item xs={12} md={12} mt={2}>
                          <Typography sx={{ color: 'red' }}>Investment cant withdrawn within 1 year of deposit date.</Typography>{' '}
                        </Grid>
                      )}

                      <Grid item xs={12} md={12} mt={2}>
                        {/* <Button disabled={isLoading} type="submit" variant="contained" my={2} px={3}>
                          Submit
                        </Button> */}
                        <Button disabled={true} type="submit" variant="contained" my={2} px={3}>
                          Submit
                        </Button>

                        <Button onClick={handleClickCancel}>Cancel</Button>
                      </Grid>
                    </Form>
                  </FormikProvider>
                </Grid>
                <Grid item xs={0} md={2}></Grid>
              </Grid>
            </Card>
          </Grid>
          <SimpleModal
            isOpen={openModal}
            onClose={handleCloseModal}
            title={modalData.title}
            content={modalData.content}
            navigateUrl={modalData.navigateUrl}
          />
          <Modal
            open={otpModalOpen}
            onClose={handleCloseOtp}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <OTPVerification verifyOtp={verifyOTP} resendOTP={resendOTP} />
            </Box>
          </Modal>
        </Grid>
      )}
    </Grid>
  );
};

export default CapitalWithdrawalForm;
