import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'store/auth/actions';

const SignOut = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
  }, []);
  return <div>SignOut</div>;
};

export default SignOut;
