import api from 'utils/api';
import { GET_RETURN_DATA, GET_WITHDRAWALS } from './constants';

export const getReturnData = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/get_return_data');
    dispatch({
      type: GET_RETURN_DATA,
      payload: data?.objSettings
    });
  } catch (error) {
    console.log(error);
  }
};
export const createWithdrawalReq = (body) => async () => {
  const { data } = await api.post('/create_return', body);
  return data;
};
export const getWithdrawals = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/get_return_list', body);
    dispatch({
      type: GET_WITHDRAWALS,
      payload: data?.arrList
    });
  } catch (error) {
    console.log(error);
  }
};
