import api from 'utils/api';
import { GET_INVESTMENTS } from './constants';

export const getInvestment = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/get_investment', body);
    dispatch({
      type: GET_INVESTMENTS,
      payload: data?.arrList
    });
  } catch (error) {
    console.log(error);
  }
};
export const approveInvestment = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/approve_investment', body);
    dispatch(getInvestment({ chrStatus: 'F' }));
  } catch (error) {
    console.log(error);
  }
};
export const createInvestment = (body) => async (dispatch) => {
  const { data } = await api.post('/create_investment', body);
  dispatch(getInvestment({ chrStatus: 'P' }));
  return data;
};
export const createTopupInvestment = (body) => async (dispatch) => {
  const { data } = await api.post('/create_topup_investment', body);
  dispatch(getInvestment({ chrStatus: 'P' }));
  return data;
};

export const updateLockType = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/common_api', body);
    dispatch(getInvestment({ chrStatus: 'N' }));
  } catch (error) {
    console.log(error);
  }
};
