import React from 'react';
import awards from 'assets/images/homepage/Awards.jpeg';
import { Grid, Typography } from '@mui/material';
import CustomScrollAnimation from 'ui-component/animations/CustomScrollAnimation';

const Awards = () => {
  return (
    <CustomScrollAnimation>
      <Grid container style={{ backgroundColor: 'black' }}>
        <Typography
          variant="h1"
          sx={{
            width: '100%',
            fontWeight: 'bold',
            color: 'white',
            textAlign: 'center',
            // borderBottom: "2px solid black",
            mb: 5
          }}
        >
          Awards
        </Typography>
        <Grid item xs={12} md={12}>
          <img
            src={awards}
            alt="awards"
            style={{ width: '-webkit-fill-available', border: 'solid 4px #E2FFD3', borderRight: 'none', borderLeft: 'none' }}
          />
        </Grid>
      </Grid>
    </CustomScrollAnimation>
  );
};

export default Awards;
