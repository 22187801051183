import React from 'react';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
// import Update from '../auth-forms/Update';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Alert from '@mui/material/Alert';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery
  // useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import { Hidden } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CopyrightRoundedIcon from '@mui/icons-material/CopyrightRounded';
import { customAlphabet } from 'nanoid';
// import EduApp from 'assets/Icons/EduApp.svg';
// import MyIcon from 'assets/Icons/ImageDown.svg';

// store
import { display, margin } from '@mui/system';
import { values } from 'lodash';
import { useEffect } from 'react';
import api from 'utils/api';

// ============================|| FIREBASE - LOGIN ||============================ //
const StyledHelperText = styled('div')({
  color: 'red'
});

export default function ChangeTransPassword({ token, setIndexValue }) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:960px)');
  const isLargeLaptop = useMediaQuery('(min-width:1280px)');
  const isMediumLaptop = useMediaQuery('(min-width:1024px) and (max-width:1279px)');

  //   useEffect(() => {
  //     if (!token) {
  //       setIndexValue(0);
  //     }
  //   }, [token]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();

  const [response, setResponse] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const passwordClick = () => {
    setShowPassword(!showPassword);
  };
  const passwordClick1 = () => {
    setShowPassword1(!showPassword1);
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string().required('New password is required'),
    //   .min(6, 'New password must be at least 6 characters long')
    //   .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]+$/,
    //     'New password must contain at least one uppercase letter, one lowercase letter, and one number'
    //   ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required')
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      try {
        // console.log(values, 'values');
        const datavalue = {
          strToken: token,
          strNewLoginPassword: values.newPassword
        };
        const { data } = await api.post('/change_trans_forgot_password', datavalue);
        console.log(data);
        setIndexValue(3);
        // const respond = await dispatch(updatePassword(datavalue));
        // console.log('final response is...', respond);
        // setResponse(respond);
        // setTimeout(function () {
        //     if (respond.data.success == true) {
        //         handleOpen();
        //         setSubmitting(false);
        //     }
        // }, 2000);
      } catch (err) {
        console.error(err);
        setSubmitting(false);
        setErrors(err);
      }
      console.log('values are', values.newPassword);
    }
  });

  // console.log('values.NewPassword', formik.values.newPassword);
  // console.log(formik.values.confirmPassword);

  return (
    <>
      <Grid item md={12} xs={12} sx={{ backgroundColor: '' }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid item lg={12} md={12} sm={12} sx={{ backgroundColor: '', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Typography variant="h2" sx={{ color: '#1699D7' }}>
              Create New Password
            </Typography>
          </Grid>

          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '',
              marginTop: '30px'
            }}
            item
            lg={12}
            md={12}
            sm={12}
          >
            <TextField
              label=" New Password"
              name="newPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newPassword}
              type={showPassword ? 'text' : 'password'}
              helperText={
                formik.touched.newPassword && formik.errors.newPassword ? (
                  <StyledHelperText>{formik.errors.newPassword}</StyledHelperText>
                ) : null
              }
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={passwordClick}>
                    {/* <img src={EyeIcon} alt="Icon" /> */}
                    {showPassword ? <Visibility sx={{ color: '#1699D7' }} /> : <VisibilityOff sx={{ color: '#1699D7' }} />}
                  </InputAdornment>
                )
              }}
              inputProps={{
                autoComplete: 'new-password'
              }}
            />
          </Grid>

          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '',
              marginTop: '30px'
            }}
            item
            lg={12}
            md={12}
            sm={12}
          >
            <TextField
              label="Confirm Password"
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              type={showPassword1 ? 'text' : 'password'}
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <StyledHelperText>{formik.errors.confirmPassword}</StyledHelperText>
                ) : null
              }
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={passwordClick1}>
                    {/* <img src={EyeIcon} alt="Icon" /> */}
                    {showPassword1 ? <Visibility sx={{ color: '#1699D7' }} /> : <VisibilityOff sx={{ color: '#1699D7' }} />}
                  </InputAdornment>
                )
              }}
              inputProps={{
                autoComplete: 'new-password'
              }}
            />
          </Grid>

          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: ''
            }}
            item
            lg={12}
            md={12}
            sm={12}
          >
            <Button
              // onClick={handleOpen}
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              sx={{ height: '45px', fontSize: '17px', fontWeight: 500, marginTop: '30px' }}
            >
              Update
            </Button>

            {/* <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                  <Update message={'Password Updated'} />
                </Modal> */}
          </Grid>
        </form>
      </Grid>
    </>
  );

  // <>
  //     <Formik
  //         initialValues={{
  //             username: '',
  //             otp: '',
  //             newotp: '',
  //             password: '',
  //             passwordConfirmation: ''
  //         }}
  //         validationSchema={otpData == null && verify == false ? validation1 : otpData && verify == false ? validation2 : validation3}
  //         onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
  //             if (otpData == null && verify == false) {
  //                 // console.log(values, 'values');
  //                 try {
  //                     const nanoid = customAlphabet('1234567890', 6);
  //                     const otpset = nanoid(); //=> "4f90d13a42"
  //                     if (values.otp === '') {
  //                         values.otp = otpset;
  //                     }
  //                     const respond = await dispatch(setOtp(values));
  //                     // console.log(respond, 'response');
  //                     setResponse(respond);
  //                     if (respond.data.success == true) {
  //                         setOtpData(respond.data.otpdata);
  // dispatch({
  //     type:'OTP_DATA',
  //     payload:respond.data.otpdata
  // })
  //                         resetForm();
  //                         setSubmitting(false);
  //                     }
  //                 } catch (err) {
  //                     console.error(err);
  //                     setSubmitting(false);
  //                     setErrors(err);
  //                 }
  //             } else if (otpData && verify == false) {
  //                 try {
  //                     // console.log(values, 'values');
  //                     const datavalue = {
  //                         userid: otpData.userid,
  //                         email: otpData.email,
  //                         otp: values.newotp
  //                     };
  //                     const respond = await dispatch(verifyOtp(datavalue));
  //                     // console.log(respond, 'response');
  //                     setResponse(respond);
  //                     if (respond.data.success == true) {
  //                         setVerify(true);
  //                         resetForm();
  //                         setSubmitting(false);
  //                     }
  //                 } catch (err) {
  //                     console.error(error);
  //                     setSubmitting(false);
  //                     setErrors(error);
  //                 }
  //             } else {
  //                 try {
  //                     // console.log(values, 'values');
  //                     const datavalue = {
  //                         id: otpData.userid,
  //                         password: values.password
  //                     };
  //                     const respond = await dispatch(updatePassword(datavalue));
  //                     setResponse(respond);
  //                     setTimeout(function () {
  //                         if (respond.data.success == true) {
  //                             navigate('/pages/login/login3');
  //                             // resetForm();
  //                             setSubmitting(false);
  //                         }
  //                     }, 2000);
  //                 } catch (err) {
  //                     console.error(error);
  //                     setSubmitting(false);
  //                     setErrors(error);
  //                 }
  //             }
  //         }}
  //     >
  //         {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
  //             <form noValidate onSubmit={handleSubmit} {...others}>
  //                 {otpData == null && verify == false ? (
  //                     <>
  //                         <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
  //                             <InputLabel htmlFor="outlined-adornment-email-login">username</InputLabel>
  //                             <OutlinedInput
  //                                 id="outlined-adornment-email-login"
  //                                 type="text"
  //                                 value={values.username}
  //                                 name="username"
  //                                 onBlur={handleBlur}
  //                                 onChange={handleChange}
  //                                 label="username"
  //                                 inputProps={{}}
  //                                 error={Boolean(touched.username && errors.username)}
  //                             />
  //                             {touched.username && errors.username && (
  //                                 <FormHelperText error id="standard-weight-helper-text-username-login">
  //                                     {errors.username}
  //                                 </FormHelperText>
  //                             )}
  //                         </FormControl>
  //                         {/* <Typography
  //                             variant="subtitle1"
  //                             component={Link}
  //                             to="/"
  //                             color="secondary"
  //                             sx={{ textDecoration: 'none', cursor: 'pointer' }}
  //                         >
  //                             Know your password ?| return to login
  //                         </Typography> */}
  //                     </>
  //                 ) : otpData && verify == false ? (
  //                     <>
  //                         <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
  //                             <InputLabel htmlFor="outlined-adornment-otp-login"> Enter your OTP</InputLabel>
  //                             <OutlinedInput
  //                                 id="outlined-adornment-otp-login"
  //                                 type="text"
  //                                 value={values.newotp}
  //                                 name="newotp"
  //                                 onBlur={handleBlur}
  //                                 onChange={handleChange}
  //                                 label="OTP"
  //                                 inputProps={{}}
  //                             />
  //                         </FormControl>
  //                     </>
  //                 ) : (
  //                     <>
  //                         <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
  //                             <InputLabel htmlFor="outlined-adornment-otp-login"> Enter your password</InputLabel>
  //                             <OutlinedInput
  //                                 id="outlined-adornment-otp-login"
  //                                 type={showPassword ? 'text' : 'password'}
  //                                 endAdornment={
  //                                     <InputAdornment position="end">
  //                                         <IconButton
  //                                             aria-label="toggle password visibility"
  //                                             onClick={handleClickShowPassword}
  //                                             onMouseDown={handleMouseDownPassword}
  //                                             edge="end"
  //                                         >
  //                                             {showPassword ? <VisibilityOff /> : <Visibility />}
  //                                         </IconButton>
  //                                     </InputAdornment>
  //                                 }
  //                                 value={values.password}
  //                                 name="password"
  //                                 onBlur={handleBlur}
  //                                 onChange={handleChange}
  //                                 label="password"
  //                                 inputProps={{}}
  //                                 error={Boolean(touched.password && errors.password)}
  //                             />
  //                             {touched.password && errors.password && (
  //                                 <FormHelperText error id="password">
  //                                     {errors.password}
  //                                 </FormHelperText>
  //                             )}
  //                         </FormControl>
  //                         <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
  //                             <InputLabel htmlFor="outlined-adornment-otp-login"> Confirm your password</InputLabel>
  //                             <OutlinedInput
  //                                 id="outlined-adornment-otp-login"
  //                                 type={showPassword ? 'text' : 'password'}
  //                                 endAdornment={
  //                                     <InputAdornment position="end">
  //                                         <IconButton
  //                                             aria-label="toggle password visibility"
  //                                             onClick={handleClickShowPassword}
  //                                             onMouseDown={handleMouseDownPassword}
  //                                             edge="end"
  //                                         >
  //                                             {showPassword ? <VisibilityOff /> : <Visibility />}
  //                                         </IconButton>
  //                                     </InputAdornment>
  //                                 }
  //                                 value={values.passwordConfirmation}
  //                                 name="passwordConfirmation"
  //                                 onBlur={handleBlur}
  //                                 onChange={handleChange}
  //                                 label="passwordConfirmation"
  //                                 inputProps={{}}
  //                                 error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
  //                             />
  //                             {touched.passwordConfirmation && errors.passwordConfirmation && (
  //                                 <FormHelperText error id="password">
  //                                     {errors.passwordConfirmation}
  //                                 </FormHelperText>
  //                             )}
  //                         </FormControl>
  //                     </>
  //                 )}
  //                 {response ? <Alert severity={response.data.status}>{response.data.message}</Alert> : <></>}
  //                 {errors.submit && (
  //                     <Box sx={{ mt: 3 }}>
  //                         <FormHelperText error>{errors.submit}</FormHelperText>
  //                     </Box>
  //                 )}
  //                 <Box sx={{ mt: 2 }}>
  //                     <AnimateButton>
  //                         <Button
  //                             disableElevation
  //                             disabled={isSubmitting}
  //                             fullWidth
  //                             size="large"
  //                             type="submit"
  //                             variant="contained"
  //                             color="secondary"
  //                         >
  //                             {otpData == null && verify == false
  //                                 ? 'Send '
  //                                 : otpData && verify == false
  //                                 ? 'Verify '
  //                                 : 'Change password '}
  //                             {otpData == null && verify == false ? (
  //                                 <SendIcon style={{ marginLeft: '.5rem' }} fontSize="small" />
  //                             ) : otpData && verify == false ? (
  //                                 <CheckCircleIcon style={{ marginLeft: '.5rem' }} fontSize="small" />
  //                             ) : (
  //                                 <ChangeCircleIcon style={{ marginLeft: '.5rem' }} fontSize="small" />
  //                             )}
  //                         </Button>
  //                     </AnimateButton>
  //                     {otpData == null || verify == false ? (
  //                         <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: 10 }}>
  //                             <Typography
  //                                 variant="subtitle1"
  //                                 component={Link}
  //                                 to="/"
  //                                 color="secondary"
  //                                 sx={{ textDecoration: 'none', cursor: 'pointer' }}
  //                             >
  //                                 Know your password ? return to login
  //                             </Typography>
  //                         </Grid>
  //                     ) : (
  //                         <></>
  //                     )}
  //                 </Box>
  //             </form>
  //         )}
  //     </Formik>
  // </>
}
