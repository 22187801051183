import { Alert, Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import OtpInput from 'react-otp-input';
import api from 'utils/api';

const VerifyTransOTPForgot = ({ userId, setToken, setIndexValue, otpError, isLoading }) => {
  const [time, setTime] = useState(30);
  //State for storing entered OTP
  const [otp, setOtp] = useState('');
  const [errMsg, setErrMsg] = useState('');

  //Function to handle OTP change
  const handleOtpChange = (newValue) => {
    // if (otp.length < 7){}
    setOtp(newValue);
  };

  //Function to handle resend OTP
  const handleResend = (e) => {
    resendOTP();
    setTime(30);
  };
  const decreaseNum = () => setTime((prev) => (prev > 0 ? prev - 1 : 0));

  const intervalRef = useRef();
  useEffect(() => {
    //Timer function
    intervalRef.current = setInterval(() => {
      if (time > 0) {
        decreaseNum();
      } else {
        clearInterval(intervalRef.current);
      }
    }, 1000);
    return () => clearInterval(intervalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const verifyOtp = async (otp) => {
    try {
      const { data } = await api.post('/verify_trans_forgot_otp', { strUserId: userId, strOTP: otp });
      console.log(data);
      setToken(data?.strToken);
      setIndexValue(2);
    } catch (error) {
      setErrMsg('Something went wrong. Try again.');
      setTimeout(() => {
        setErrMsg('');
      }, 3000);
    }
  };
  return (
    <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1}>
      <Typography component="h6" variant="h6" color="primary" mb={2}>
        VERIFY OTP
      </Typography>
      <Typography component="h6" variant="h6" color="primary" mb={2}>
        We have sent an otp to the respective mail id,
      </Typography>
      <Box width="100%" height="40px">
        <Typography variant="body2" sx={{ fontSize: '14px', mt: '5px', color: 'black' }}>
          Enter OTP
        </Typography>
        <Box textAlign="center" mt="-5px">
          {otpError && <span style={{ color: 'red', fontSize: '12px' }}>{otpError}</span>}
        </Box>
      </Box>
      <OtpInput
        onChange={handleOtpChange}
        value={otp}
        numInputs={6}
        shouldAutoFocus
        inputType="number"
        inputStyle={{
          width: '40px',
          height: '40px',
          fontSize: '24px',
          margin: '0 5px',
          borderRadius: '8px',
          border: '2px solid blue',
          backgroundColor: '#E0E0E0',
          color: '#333'
        }}
        // style={{ border: 'none' }}
        // style={{
        //   //   Add your inline styles here
        //   width: '30px', // Example style properties
        //   height: '40px',
        //   fontSize: '20px',
        //   border: '1px solid red',
        //   borderRadius: '5px'
        // }}
        containerStyle={{ height: '55px', borderColor: 'blue !Important' }}
        // containerStyle={{
        //   height: '40px',
        //   display: 'flex',
        //   justifyContent: 'space-between',
        //   width: '150px', // Adjust the width as needed
        //   borderColor: 'red !Important'
        // }}
        renderInput={(props) => <input {...props} />}
      />
      {errMsg && (
        <Grid item xs={12} md={12}>
          <Alert variant="filled" severity="error">
            {errMsg}
          </Alert>
        </Grid>
      )}
      <Button variant="contained" disabled={otp.length >= 6 ? false : true} fullWidth sx={{ mt: '20px' }} onClick={() => verifyOtp(otp)}>
        {isLoading && (
          <CircularProgress
            sx={{
              width: '13px !important',
              height: '13px !important',
              color: '#fff',
              marginRight: '5px'
            }}
          />
        )}
        VERIFY
      </Button>
      <Typography variant="body2" sx={{ color: 'black' }} mt={2}>
        Didn{"'"}t you receive any OTP?
      </Typography>
      {time === 0 ? (
        <Button variant="text" onClick={(e) => handleResend(e)} sx={{ mt: '10px', fontSize: '16px !important' }}>
          Resend OTP
        </Button>
      ) : (
        <Button sx={{ mt: '10px', fontSize: '16px !important' }}>{time < 10 ? '00:0' + time : '00:' + time}</Button>
      )}
    </Grid>
  );
};

export default VerifyTransOTPForgot;
