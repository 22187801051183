import { Card, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import api from 'utils/api';

const AssociatesUsersList = () => {
  const [usersList, setUsersList] = useState([]);
  const [totalAmt, setTotalAmt] = useState(0);
  //   const { currentRefCodes } = useSelector((state) => state?.helpers);
  const [currentRefCodes, setCurrentRefCodes] = useState(null);
  useEffect(() => {
    const retrievedObject = JSON.parse(localStorage.getItem('currentAssociates'));
    setCurrentRefCodes(retrievedObject);
    return () => {
      localStorage.removeItem('currentAssociates');
    };
  }, []);
  console.log(currentRefCodes);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.post('/get_userslist_by_refcodearr', { strRefCode: currentRefCodes.arrRefCodes });
        setUsersList(data?.arrList);
      } catch (error) {
        console.log(error);
      }
    };
    if (currentRefCodes?.arrRefCodes?.length > 0) {
      fetchData();
    }
  }, [currentRefCodes]);

  useEffect(() => {
    if (usersList.length > 0) {
      let totalCapitalAmount = 0;
      usersList.forEach((user) => {
        let userTotal = user.arrInvestMents.reduce((acc, investment) => {
          return acc + investment.intCapitalAmt;
        }, 0);
        // Add individual user's intCapitalAmt to the total
        totalCapitalAmount += userTotal;
      });
      setTotalAmt(totalCapitalAmount);
    }
  }, [usersList]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h3">{`Level ${currentRefCodes?.level} Users`}</Typography>
            </Grid>
            <Grid item xs={4} display={'flex'} justifyContent={'right'}>
              <Chip label={`$${totalAmt}`} />
              {/* <Typography variant="h3">${totalAmt}</Typography> */}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Userame</TableCell>
                <TableCell>Sponsor Id</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Investments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersList?.map((rootUser) => (
                <TableRow key={rootUser?._id}>
                  <TableCell>{rootUser.strName}</TableCell>
                  <TableCell>{rootUser.strRefCodeBy}</TableCell>
                  <TableCell>{rootUser.strEmail}</TableCell>
                  <TableCell sx={{ fontWeight: '700' }}>
                    {/* {userSub.arrInvestments?.map((investment) => investment?.intCapitalAmt).reduce((acc, currentValue) => acc + currentValue)}
                     */}
                    ${Math.abs(rootUser.arrInvestMents.reduce((total, investment) => total + investment.intCapitalAmt, 0)).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default AssociatesUsersList;
