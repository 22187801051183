import React from 'react';
import HomeHeader from 'ui-component/homeheader/HomeHeader';
import blockChainDeveloper from 'assets/images/homepage/AboutUs/BlockchainDeveloper.jpeg';
import ceo from 'assets/images/homepage/AboutUs/CEO.jpeg';
import { Grid, Paper, Typography } from '@mui/material';
import Footer from '../Footer';
import FromLeftAnim from 'ui-component/animations/FromLeftAnim';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
  return (
    <div style={{ backgroundColor: '#000' }}>
      <Helmet>
        <title>About Us - Royal Prime Fx</title>
        <meta name="description" content="Learn about Royal Prime Fx and its CEO, Hasan Peker, and more." />
        <meta name="keywords" content="Royal Prime Fx, about us, CEO, Hasan Peker, leadership, and more" />
        <meta name="author" content="Hasan Peker" />
        <meta name="og:title" property="og:title" content="About Us - ABCD" />
        <meta name="og:description" property="og:description" content="Learn about Royal Prime Fx and its CEO, Hasan Peker, and more." />
      </Helmet>
      <HomeHeader />
      <FromLeftAnim value={-25}>
        <Section1 title="About Us">
          <Typography sx={{ fontSize: '16px', color: '#fff', textAlign: 'justify' }}>
            Welcome to Royal Prime Fx, where innovation meets excellence in the world of various projects. As a dynamic player in the
            industry, we specialize in offering a spectrum of projects that redefine the trading landscape.
          </Typography>
        </Section1>
      </FromLeftAnim>
      <Section2 title={'Our Expertise'}>
        <TypographySection title={'Forex Trading'}>
          Harness the power of global markets with our cutting-edge forex trading solutions, designed to optimize your investment
          strategies.
        </TypographySection>
        <TypographySection title={'Trading Workshops'}>
          Empower yourself with knowledge through our comprehensive trading workshops, providing insights from seasoned professionals to
          beginners alike.
        </TypographySection>
        <TypographySection title={'Blockchain Development Workshop'}>
          Dive into the future of finance with our immersive blockchain development workshops, unraveling the potential of decentralized
          technologies.
        </TypographySection>
      </Section2>
      <Section2 title={'Immersive Experiences'}>
        <TypographySection title={'Realverse'}>
          Step into the realverse, where reality meets innovation, and unlock unparalleled opportunities in the trading realm.
        </TypographySection>
        <TypographySection title={'Metaverse'}>
          Explore the limitless possibilities of the metaverse, where virtual experiences seamlessly intertwine with real-world financial
          advancements.
        </TypographySection>
        <TypographySection title={'Virtual Reality Trading'}>
          Immerse yourself in a new dimension of trading with our virtual reality solutions, enhancing decision-making and strategy
          execution.
        </TypographySection>
        <TypographySection title={'Augmented Reality Trading'}>
          Bridge the gap between the physical and digital worlds with our augmented reality trading experiences, revolutionizing the way you
          interact with markets.
        </TypographySection>
      </Section2>
      <Section2 title={'Meet Our Royal Club Team'}>
        <TypographySection title={''}>
          At the heart of our success is the Royal Club, a dedicated team of experts committed to elevating your trading journey. With a
          wealth of experience and a passion for excellence, they bring a unique blend of skills to ensure you receive the royal treatment
          in every aspect of your trading endeavors.
        </TypographySection>
      </Section2>
      <Section2 title={'Why Choose Royal Prime Fx'}>
        <TypographySection title={'Unparalleled Expertise'}>
          Benefit from our in-depth industry knowledge and a track record of successful ventures.
        </TypographySection>
        <TypographySection title={'Innovation at its Core'}>
          Stay ahead with our avant-garde approach to technology, consistently pushing the boundaries of what&apos;s possible.
        </TypographySection>
        <TypographySection title={'Client-Centric Philosophy'}>
          Your success is our priority, and we go the extra mile to ensure you achieve your financial goals.
        </TypographySection>
      </Section2>

      {/* CEO Section */}
      <FromLeftAnim value={25}>
        <Section title="Hasan Peker" subTitle="CEO" imageSrc={ceo}>
          {/* <Image src={ceo} alt="CEO" /> */}

          <Typography sx={{ fontSize: '16px', color: '#fff', textAlign: 'justify' }}>
            Mr. Hassan is a promising entrepreneur, emerging in the business world with multiple ventures under his belt. His diverse
            portfolio of business entities suggests a keen entrepreneurial spirit and a willingness to explore various industries. With a
            forward-thinking approach, Mr. Hassan is likely navigating the challenges of the business landscape, showcasing resilience and
            adaptability. His trajectory as an emerging businessman holds the promise of continued growth and success in the dynamic realm
            of entrepreneurship.
          </Typography>
        </Section>
      </FromLeftAnim>

      {/* Blockchain Developer Section */}
      <FromLeftAnim value={-25}>
        <Section title="Tatiana Hilton" subTitle="Blockchain Developer" imageSrc={blockChainDeveloper}>
          <Typography sx={{ fontSize: '16px', color: '#fff', textAlign: 'justify' }}>
            Tatiana is a well versed professional with over 6 years of expertise in blockchain development. His extensive experience
            reflects a deep understanding of the intricate world of decentralized technologies. Throughout his career, Tatiana has likely
            contributed to the evolution of blockchain projects, demonstrating a commitment to innovation and staying abreast of industry
            advancements. His wealth of knowledge positions him as a valuable asset in the dynamic and rapidly evolving field of blockchain
            development
          </Typography>
          {/* <Image src={blockChainDeveloper} alt="Blockchain Developer" /> */}
        </Section>
      </FromLeftAnim>
      <Footer />
    </div>
  );
};

const Section1 = ({ title, children }) => {
  return (
    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography variant="h3" align="center" gutterBottom sx={{ color: '#fff' }}>
              {title}
            </Typography>

            <Paper style={{ padding: '20px', backgroundColor: '#000' }}>{children}</Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const Section2 = ({ title, children }) => {
  return (
    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography variant="h3" align="left" gutterBottom sx={{ color: '#fff', padding: '0px 20px' }}>
              {title}
            </Typography>

            <Paper style={{ padding: '5px 20px', backgroundColor: '#000' }}>{children}</Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const Section = ({ title, subTitle, children, imageSrc }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Image src={imageSrc} alt={title} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" align="center" gutterBottom sx={{ color: '#fff' }}>
              {title}
            </Typography>
            <Typography variant="h5" align="center" gutterBottom sx={{ color: '#fff' }}>
              {subTitle}
            </Typography>
            <Paper style={{ padding: '20px', backgroundColor: '#000' }}>{children}</Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const Image = ({ src, alt }) => {
  return (
    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <img
        src={src}
        alt={alt}
        style={{ width: '120px', maxWidth: '60%', height: '120px', borderRadius: '50%', objectFit: 'cover', objectPosition: 'top' }}
      />
    </div>
  );
};

const TypographySection = ({ title, children }) => {
  return (
    <Typography sx={{ color: 'white', marginTop: '5px', fontSize: '16px', textAlign: 'justify' }}>
      <span style={{ fontWeight: 600 }}>{title ? title + ':' : ''}</span>
      {children}
    </Typography>
  );
};

export default AboutUs;
