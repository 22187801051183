import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import HomePage from 'views/home/HomePage';
import GuestRoute from 'guards/GuestRoute';
import PrivacyPolicy from 'views/home/PrivacyPolicy';
import Faq from 'views/home/Faq';
import AboutUs from 'views/home/AboutUs/AboutUs';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: (
    <GuestRoute>
      <MinimalLayout />
    </GuestRoute>
  ),
  children: [
    {
      path: '/',
      element: <HomePage />
    },
    {
      path: '/login',
      element: <AuthLogin3 />
    },
    {
      path: '/register',
      element: <AuthRegister3 />
    },
    {
      path: '/privacy',
      element: <PrivacyPolicy />
    },
    {
      path: '/faq',
      element: <Faq />
    },
    {
      path: '/aboutus',
      element: <AboutUs />
    }
  ]
};

export default AuthenticationRoutes;
