import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import api from 'utils/api';
import { useSelector } from 'react-redux';

const ReferralNetwork = () => {
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { user } = useSelector((state) => state?.auth);
  const getDirectUsers = async () => {
    const { data } = await api.post('http://localhost:4000/get_ref_legs', { strRefCode: user?.strRefCode });
    setUserList(data?.arrList);
  };
  useEffect(() => {
    if (user?.strRefCode) {
      getDirectUsers();
    }
  }, [user]);
  //   useEffect(() => {
  //     // Initial API call with your referral code in the body
  //     fetch('http://127.0.0.1:4000/get_ref_legs', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ strRefCode: 'ABCExample4' }),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setUserList(data.arrList);
  //       })
  //       .catch((error) => console.error('Error fetching data:', error));
  //   }, []);

  const handleUserClick = (user) => {
    // Set the selected user in local state
    setSelectedUser(user);
    const fetchSubUsers = async () => {};
    // Make your subsequent API call here using user.strRefCode
    // Example fetch call:
    // fetch(`http://127.0.0.1:4000/get_ref_legs`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ strRefCode: user.strRefCode }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     // Handle the API response and update the user list
    //   })
    //   .catch((error) => console.error('Error fetching data:', error));
  };

  const renderSubUsers = (user, level) => {
    if (user.arrList && user.arrList.length > 0) {
      return (
        <Table>
          <TableBody>
            {user.arrList.map((subUser) => (
              <React.Fragment key={subUser._id}>
                <TableRow onClick={() => handleUserClick(subUser)} style={{ cursor: 'pointer' }}>
                  <TableCell>{subUser.strName}</TableCell>
                  <TableCell>{subUser.strFullName}</TableCell>
                  <TableCell>{subUser.strMobileNo}</TableCell>
                  <TableCell>{subUser.strEmail}</TableCell>
                </TableRow>
                {selectedUser && selectedUser._id === subUser._id && renderSubUsers(subUser, level + 1)}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      );
    }
    return null;
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="User Table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Full Name</TableCell>
            <TableCell>Mobile No</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userList.map((user) => (
            <React.Fragment key={user._id}>
              <TableRow onClick={() => handleUserClick(user)} style={{ cursor: 'pointer' }}>
                <TableCell>{user.strName}</TableCell>
                <TableCell>{user.strFullName}</TableCell>
                <TableCell>{user.strMobileNo}</TableCell>
                <TableCell>{user.strEmail}</TableCell>
              </TableRow>
              {selectedUser && selectedUser._id === user._id && renderSubUsers(user, 1)}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReferralNetwork;
