import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletDetails } from 'store/wallet/actions';
import TotalWalletAmount from './TotalWalletAmount';
import PopularCard from 'views/dashboard/CustomOne/PopularCard';

const Wallet = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  useEffect(() => {
    dispatch(getWalletDetails({ _id: user._id }));
  }, []);
  const { walletDetails } = useSelector((state) => state?.wallet);
  const referalTotal = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'REFERAL');
  const dailyProfit = walletDetails?.arrWalletsTotals?.find((walletsTotal) => walletsTotal.strIncomeType === 'PROFIT');

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12} md={12}>
        <Card sx={{ p: '16px' }}>
          <Typography variant="h3">Wallet</Typography>
        </Card>
      </Grid> */}
      <Grid item xs={12} md={12}>
        <PopularCard
          walletArray={walletDetails?.arrList}
          walletAmount={walletDetails?.arrTotals[0]?.intAmt}
          isDashboard={false}
          isLoading={false}
        />
      </Grid>
    </Grid>
  );
};

export default Wallet;
