import { Alert, Button, Card, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from 'store/auth/actions';
import * as Yup from 'yup';

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState(null);
  const formik = useFormik({
    initialValues: {
      strCurrentLoginPassword: '',
      strNewLoginPassword: '',
      strConfirmPasword: ''
    },
    validationSchema: Yup.object().shape({
      strCurrentLoginPassword: Yup.string().max(255).required('Old password is required'),
      strNewLoginPassword: Yup.string().max(255).required('New password is required'),
      strConfirmPasword: Yup.string()
        .required('Please retype your password.')
        .oneOf([Yup.ref('strNewLoginPassword'), null], 'Passwords must match')
    }),
    onSubmit: async (values) => {
      console.log(values);
      const data = {
        strCurrentLoginPassword: values.strCurrentLoginPassword,
        strNewLoginPassword: values.strNewLoginPassword
      };
      try {
        const res = await dispatch(resetPassword(data));
        console.log(res);
        navigate(-1);
      } catch (error) {
        console.log(error);
        setErrMsg(error?.response?.data?.strMessage);
        setTimeout(() => {
          setErrMsg(null);
        }, 4000);
      }
    }
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Reset Password</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <Grid item xs={12} md={12}>
                <Card sx={{ padding: '16px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <TextField name="strCurrentLoginPassword" onChange={handleChange} fullWidth label="Old Password" />
                      {touched.strCurrentLoginPassword && errors.strCurrentLoginPassword && (
                        <FormHelperText error id="standard-weight-helper-text-password-register">
                          {errors.strCurrentLoginPassword}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField name="strNewLoginPassword" onChange={handleChange} fullWidth label="New Password" />
                      {touched.strNewLoginPassword && errors.strNewLoginPassword && (
                        <FormHelperText error id="standard-weight-helper-text-password-register">
                          {errors.strNewLoginPassword}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField name="strConfirmPasword" onChange={handleChange} fullWidth label="Confirm New Password" />
                      {touched.strConfirmPasword && errors.strConfirmPasword && (
                        <FormHelperText error id="standard-weight-helper-text-password-register">
                          {errors.strConfirmPasword}
                        </FormHelperText>
                      )}
                    </Grid>
                    {errMsg && (
                      <Grid item xs={12} md={12}>
                        <Alert variant="filled" severity="error">
                          {errMsg}
                        </Alert>
                      </Grid>
                    )}

                    <Grid item xs={12} md={12}>
                      <Button variant="contained" fullWidth type="submit">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Form>
          </FormikProvider>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
