import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import HomeHeader from 'ui-component/homeheader/HomeHeader';

const PrivacyPolicy = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <HomeHeader />
      </Grid>
      <Grid item xs={12} md={12} sx={{ backgroundColor: '#030708' }}>
        <Grid container>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={10} md={10}>
            <Card variant="outlined" sx={{ p: '10px', backgroundColor: 'transparent', borderColor: 'white' }}>
              <Grid container>
                <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'} sx={{ mt: 5, p: 1 }}>
                  <Typography sx={{ color: 'white' }} variant="h4">
                    Privacy Policy
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container justifyContent="left" sx={{ mt: 1, p: 1 }}>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      Royal Prime fx is committed to protecting your privacy and safeguarding your personal information.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      1. Information We Collect
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      We may collect the following types of information:
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      Personal Information: This may include your name, email address, contact information, and other identifying details
                      required for account creation and customer support.
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      Financial Information: If you engage in financial transactions with us, we may collect financial data, such as bank
                      account information or cryptocurrency wallet addresses.
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      Usage Data: We collect information about how you interact with our platforms and services.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      2. How We Use Your Information
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      We may use your information for the following purposes:
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      To Provide Services: We use your data to provide you with our Forex trading and Metaverse-related services, including
                      account management and customer support.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      Security: Your data is crucial for verifying your identity, preventing fraud, and ensuring the security of our
                      platforms.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      Communication: We may use your contact information to communicate with you regarding service updates, account
                      notifications, and marketing communications. You can opt out of marketing communications at any time.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      3. Data Sharing and Disclosure
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      We may share your data with third parties under the following circumstances:
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      Service Providers: We may engage third-party service providers to assist us in delivering our services. These
                      providers have access to your data only to perform specific tasks on our behalf.
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      Legal Compliance: We may disclose your data to comply with legal obligations, such as responding to subpoenas, court
                      orders, or government requests.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      4. Your Privacy Choices
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      You have the following privacy choices:
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      Access and Correction: You can access and update your personal information by logging into your account.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      Data Deletion: You can request the deletion of your account and associated data.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      Opt-Out: You can opt out of marketing communications by following the instructions in our communications.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      5. Security
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      We take appropriate measures to protect your data from unauthorized access, disclosure, alteration, or destruction.
                      However, please note that no online transmission or data storage is completely secure.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      6. Changes to this Policy
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      We may update this Privacy Policy to reflect changes in our practices or legal requirements. We will notify you of
                      significant changes through our platforms or via email.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2} variant="h4">
                      Anti-Money Laundering (AML) Policy
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      1. Introduction
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      Royal Prime Fx is committed to upholding the highest standards of ethical conduct and regulatory compliance,
                      particularly in the context of Forex trading and activities related to the Metaverse.{' '}
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      This Anti-Money Laundering (AML) Policy outlines our dedication to preventing and detecting money laundering and
                      terrorist financing activities.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      2. Purpose
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      The purpose of this AML Policy is to:
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      Establish procedures for identifying and verifying the identities of our customers. Detect and report suspicious
                      activities that may be indicative of money laundering or terrorist financing. Ensure compliance with applicable laws
                      and regulations related to AML and Know Your Customer (KYC) requirements.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      3. Customer Due Diligence (CDD)
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      We will perform customer due diligence, which includes the following measures:
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      Customer Identification: We will verify the identity of all customers using reliable and independent sources of
                      information.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      2. How We Use Your Information
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      Enhanced Due Diligence (EDD): For higher-risk customers, we will conduct enhanced due diligence to obtain additional
                      information.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      {' '}
                      Ongoing Monitoring: We will continuously monitor customer transactions and behavior for unusual or suspicious
                      activities.
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      4. Reporting Obligations
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      We are committed to complying with all reporting requirements concerning suspicious activities, including filing
                      Suspicious Activity Reports (SARs) and cooperating with relevant authorities.
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      5. Training and Awareness
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      We will provide our employees with training on AML policies and procedures to ensure they are aware of their
                      responsibilities and the importance of reporting suspicious activities.
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      6. Record Keeping
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      We will maintain comprehensive records of customer identification and transaction history as required by applicable
                      laws and regulations.
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      7. Cooperation with Authorities
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      We will fully cooperate with law enforcement agencies, regulatory authorities, and other institutions in matters
                      related to AML and the prevention of financial crimes
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      8. Risk-Based Approach
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      We will adopt a risk-based approach to AML, tailoring our procedures and due diligence to the risk associated with
                      each customer and transaction.
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      9. Sanctions Compliance
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      We will screen customers and transactions against applicable sanctions lists and take appropriate action when
                      necessary.
                    </Typography>
                    <Typography sx={{ color: 'white' }} mt={2}>
                      10. Review and Update
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      We will regularly review and update this AML Policy to ensure it remains effective and compliant with evolving
                      regulations.
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      11. Reporting Violations
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      Customers, or other parties who suspect violations of this AML Policy should report them promptly{' '}
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      12. Conclusion
                    </Typography>

                    <Typography sx={{ color: 'white' }} mt={2}>
                      We are committed to preventing money laundering and terrorist financing activities within our operations. This AML
                      Policy reflects our dedication to ethical and regulatory compliance in our industry.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={1} md={1}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicy;
