import React, { useState } from 'react';
import { Avatar, Box, Button, Container, Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getMessageHistory } from 'store/chat/actions';
import io from 'socket.io-client';
import { getAdminList } from 'store/auth/actions';
import chatBg from 'assets/images/chatBg.jpg';

const ChatUI = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  //   const [newMessage, setNewMessage] = useState('');
  const { user } = useSelector((state) => state?.auth);
  console.log(user);
  useEffect(() => {
    dispatch(getAdminList({ chrStatus: 'N', strType: 'ADMIN' }));
  }, []);
  const { adminList } = useSelector((state) => state?.auth);
  console.log('adminList', adminList);
  useEffect(() => {
    dispatch(getMessageHistory({ strChatId: adminList[0]?._id }));
  }, [adminList]);

  const { messageHistory } = useSelector((state) => state?.chat);
  useEffect(() => {
    setMessages(messageHistory);
  }, [messageHistory]);
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && !adminList[0]?._id) {
      return;
    }
    // Initialize the WebSocket connection
    const socketConnection = io(process.env.REACT_APP_CHAT_URL, {
      query: {
        token: accessToken
      }
    });
    // const socketConnection = io('http://65.0.170.77:4010', {
    //   query: {
    //     token: accessToken // Replace with your actual token
    //   }
    // });
    // const socketConnection = io('https://chat.royalprimefx.com', {
    //   query: {
    //     token: accessToken // Replace with your actual token
    //   }
    // });

    setSocket(socketConnection);

    return () => {
      socketConnection.disconnect();
    };
  }, [user, adminList]);
  useEffect(() => {
    if (!socket) return;

    socket.on('connect', () => {
      console.log('Connected to the Socket.io server');
    });
    socket.emit('join_room', {
      strChatId: adminList[0]?._id,
      strType: 'private'
    });
    socket.on('get_message', (message) => {
      setMessages((oldArray) => [...oldArray, message]);
    });

    socket.on('chat_history', (history) => {
      console.log('history', history);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from the Socket.io server');
    });
  }, [socket]);
  const sendMessage = () => {
    if (inputMessage.trim() === '') return;

    socket.emit('send_message', {
      strChatId: adminList[0]?._id,
      strMessageType: 'text',
      strType: 'private',
      strMessage: inputMessage
    });

    setInputMessage('');
  };
  const formatMessageDate = (date) => {
    console.log('date', date);
    return date.substring(0, 10);
    // const messageDate = new Date(date);
    // console.log('mesageDate', messageDate);

    // return messageDate.toLocaleDateString('en-US', {
    //   weekday: 'long',
    //   year: 'numeric',
    //   month: 'long',
    //   day: 'numeric'
    // });
  };
  return (
    // <Container maxWidth="md" style={{ marginTop: '20px' }}>
    //   <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#051114', backgroundImage: `url(${chatBg})` }}>
    //     <Grid container spacing={2} sx={{}}>
    // <Grid item xs={12}>
    //   <Box display="flex" justifyContent="center">
    //     <Avatar>A</Avatar>
    //     <Box ml={2} mt={1}>
    //       <strong>Admin</strong>
    //     </Box>
    //   </Box>
    // </Grid>
    //       <Grid item xs={12}>
    //         <Box minHeight="300px" overflowY="scroll">
    //           {messages.map((message) => (
    //             <div
    //               key={message._id}
    //               style={{
    //                 justifyContent: message.strUserId === user?._id ? 'right' : 'left',
    //                 textAlign: message.strUserId === user?._id ? 'right' : 'left',
    //                 marginBottom: '10px',
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 alignItems: message.strUserId === user?._id ? 'flex-end' : 'flex-start'
    //               }}
    //             >
    //               {/* <strong>{message.name}</strong> */}
    //               <span style={{ marginLeft: '5px', color: '#888' }}>{message?.strCreatedTime?.substring(11, 16)}</span>
    //               <div
    //                 style={{
    //                   backgroundColor: message.strUserId === user?._id ? '#2979ff' : '#f0f0f0',
    //                   color: message.strUserId === user?._id ? '#fff' : '#333',
    //                   padding: '8px',
    //                   borderRadius: '8px',
    //                   maxWidth: '70%'
    //                 }}
    //               >
    //                 {message.strMessage}
    //               </div>
    //             </div>
    //           ))}
    //         </Box>
    //       </Grid>
    // <Grid item xs={12}>
    //   <TextField
    //     fullWidth
    //     variant="outlined"
    //     placeholder="Type a message..."
    //     value={inputMessage}
    //     onChange={(e) => setInputMessage(e.target.value)}
    //     InputProps={{
    //       endAdornment: (
    //         <InputAdornment position="end">
    //           <Button variant="contained" color="primary" onClick={sendMessage}>
    //             Send
    //           </Button>
    //         </InputAdornment>
    //       )
    //     }}
    //   />
    // </Grid>
    //     </Grid>
    //   </Paper>
    // </Container>
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#051114', backgroundImage: `url(${chatBg})` }}>
        <Grid container spacing={2} sx={{}}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Avatar>A</Avatar>
              <Box ml={2} mt={1}>
                <strong>Admin</strong>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box minHeight="300px" overflowY="scroll">
              {messages.map((message, index) => (
                <div
                  key={message._id}
                  style={{
                    justifyContent: message.strUserId === user?._id ? 'right' : 'left',
                    textAlign: message.strUserId === user?._id ? 'right' : 'left',
                    marginBottom: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: message.strUserId === user?._id ? 'flex-end' : 'flex-start'
                  }}
                >
                  {/* Display date only if it's different from the previous message */}
                  {index === 0 ||
                  new Date(messages[index - 1]?.strCreatedTime).toLocaleDateString() !==
                    new Date(message.strCreatedTime).toLocaleDateString() ? (
                    <div style={{ textAlign: 'center', color: '#888', marginBottom: '5px' }}>
                      {formatMessageDate(message.strCreatedTime)}
                    </div>
                  ) : null}
                  {/* Display time and message */}
                  <div
                    style={{
                      marginLeft: '5px',
                      color: '#888',
                      textAlign: 'right'
                      // display:
                      //   index > 0 &&
                      //   new Date(messages[index - 1]?.strCreatedTime).toLocaleDateString() ===
                      //     new Date(message.strCreatedTime).toLocaleDateString()
                      //     ? 'none'
                      //     : 'block'
                    }}
                  >
                    {message?.strCreatedTime?.substring(11, 16)}
                  </div>
                  <div
                    style={{
                      backgroundColor: message.strUserId === user?._id ? '#2979ff' : '#f0f0f0',
                      color: message.strUserId === user?._id ? '#fff' : '#333',
                      padding: '8px',
                      borderRadius: '8px',
                      maxWidth: '70%'
                    }}
                  >
                    {message.strMessage}
                  </div>
                </div>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type a message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="contained" color="primary" onClick={sendMessage}>
                      Send
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ChatUI;
