import React from 'react';

import HomeHeader from 'ui-component/homeheader/HomeHeader';
import MainContent from './MainContent';
import { Grid } from '@mui/material';
import StaticCards from './StaticCards';
import SlideCards from './SlideCards';
import { WhyChooseUs } from './WhyChooseUs';
import Footer from './Footer';
import { AboutUs } from './AboutUs';
import RoyalPrimeFxTitle from 'ui-component/animations/RoyalPrimeFxTitle';
import Awards from './Awards';

const HomePage = () => {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <HomeHeader />
      <Grid container spacing={2} sx={{ bgcolor: '#030708' }}>
        {/* <Grid item xs={12} md={12}>
          <RoyalPrimeFxTitle />
        </Grid> */}

        <Grid item xs={12} md={12} style={{ overflowX: 'hidden' }}>
          <MainContent />
        </Grid>
        <Grid item xs={1} md={1}></Grid>
        <Grid item xs={10} md={10}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <StaticCards />
            </Grid>
            <Grid item xs={12} md={12}>
              <SlideCards />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <Awards />
            </Grid> */}
            {/* <Grid item xs={12} md={12}>
              <WhyChooseUs />
            </Grid>
            <Grid item xs={12} md={12}>
              <AboutUs />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={1} md={1}></Grid>
        <Grid item xs={12} md={12}>
          <Awards />
        </Grid>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
