import { Alert, Button, FormControl, Grid, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import { useState } from 'react';
import api from 'utils/api';

const ForgotSendOTP = ({ setIndexValue, setUserId }) => {
  const [strLoginId, setStrLoginId] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const theme = useTheme();
  const handleClickSubmit = async () => {
    if (!strLoginId) {
      return;
    }
    try {
      const { data } = await api.post('/send_forgot_otp', { strLoginId });
      setUserId(data?.strUserId);
      setIndexValue(1);
    } catch (error) {
      setErrMsg('Something went wrong. Try again.');
      setTimeout(() => {
        setErrMsg('');
      }, 3000);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
        <Typography variant="h4">Forgot your password?</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
          <InputLabel htmlFor="outlined-adornment-email-login">Username / Phone Number</InputLabel>
          <OutlinedInput
            onChange={(e) => setStrLoginId(e.target.value)}
            id="outlined-adornment-email-login"
            type="email"
            name="strLoginId"
            label="Username / Phone Number"
            inputProps={{}}
          />
        </FormControl>
      </Grid>
      {errMsg && (
        <Grid item xs={12} md={12}>
          <Alert variant="filled" severity="error">
            {errMsg}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
        <Button disabled={!strLoginId} variant="contained" onClick={handleClickSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default ForgotSendOTP;
