import { GET_RETURN_DATA, GET_WITHDRAWALS } from './constants';

const initialState = {
  objSettings: null
};

export default function withdraw(state = initialState, { type, payload }) {
  switch (type) {
    case GET_RETURN_DATA:
      return {
        ...state,
        objSettings: payload
      };
    case GET_WITHDRAWALS:
      return {
        ...state,
        withdrawalList: payload
      };
    default:
      return state;
  }
}
