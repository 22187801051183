import { Card, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MainCard from 'ui-component/cards/MainCard';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: '50%',
    top: -30,
    right: -180
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
    borderRadius: '50%',
    top: -160,
    right: -130
  }
}));

const HelpCenterMain = () => {
  const navigate = useNavigate();
  const handleClickWithdraw = () => {
    navigate('/user/capitalwithdrawal');
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Account Center</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card>
          <Grid container>
            <Grid item xs={12} md={12}>
              {/* <Card onClick={handleClickWithdraw}>
                <Typography variant="h4">Withdraw My Investment</Typography>
              </Card> */}
              <CardWrapper sx={{ padding: 2 }} border={false} content={false} onClick={() => handleClickWithdraw()}>
                <Grid container>
                  <Grid item xs={10} display={'flex'} alignItems={'center'}>
                    <Typography variant="h4">Withdraw My Investment</Typography>
                  </Grid>
                  <Grid item xs={2} display={'flex'} alignItems={'center'}>
                    <IconButton>
                      <ArrowForwardIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardWrapper>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HelpCenterMain;
