import { Button, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPackages } from 'store/packages/actions';
import MainCard from 'ui-component/cards/MainCard';
import CustomDrawer from 'ui-component/drawer/CustomDrawer';
import PlanCheckout from './PlanCheckout';

const NewInvestment = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPackages({ restrict: 'restrict' }));
  }, []);
  const { activePackages } = useSelector((state) => state?.packages);
  const handleClickView = (packageItem) => {
    setPackageData(packageItem);
    handleOpen();
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ backgroundColor: '#051114', padding: '16px' }}>
          <Typography sx={{ color: 'white' }} variant="h3">
            Choose A Plan
          </Typography>
        </Card>
      </Grid>
      {activePackages?.map((packages) => (
        <Grid item xs={12} md={4} key={packages?._id}>
          <MainCard title={packages?.strName} sx={{ backgroundColor: '#051114' }}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography sx={{ color: 'white' }} variant="h6">
                  {packages?.strPercentageRane}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button onClick={() => handleClickView(packages)} variant="contained">
                  View
                </Button>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default NewInvestment;
