import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentQRs } from 'store/paymentQR/actions';
import { getSettings } from 'store/settings/actions';
import uploadIcon from 'assets/images/users/uploadIcon.png';
import { createInvestment } from 'store/investments/actions';
import { getPackages } from 'store/packages/actions';
import SimpleModal from 'ui-component/modals/SimpleModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import clipboardCopy from 'clipboard-copy';

const PlanCheckout = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const [subscriptionFeePerc, setSubscriptionFeePerc] = useState(0);
  const [processingAmount, setProcessingAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [transBase64, setTransBase64] = useState('');
  const [transFileName, setTransFileName] = useState(null);
  const [transImage, setTransImage] = useState(null);
  const [errMsg, setErrMsg] = useState('');
  const [packageData, setPackageData] = useState(null);
  const [walletAddresErr, setWalletAddressErr] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');
  const [open, setOpen] = React.useState(false);
  const [textValue, setTextValue] = useState('TRC20');
  const [typeValue, setTypeValue] = useState();
  const [amountValue, setAmountValue] = useState(0);
  const [investmentLockType, setInvestmentLockType] = useState('active');

  useEffect(() => {
    dispatch(getSettings({ strType: 'settings', type: 'commonList' }));
    dispatch(getPaymentQRs({ strType: 'payment_methodes', type: 'commonList' }));
  }, []);
  useEffect(() => {
    dispatch(getPackages({ restrict: 'restrict' }));
  }, []);
  const { activePackages } = useSelector((state) => state?.packages);
  const { paymentQRlists } = useSelector((state) => state?.paymentQR);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [openModalErr, setOpenModalErr] = useState(false);
  const handleOpenModalErr = () => {
    setOpenModal(true);
  };
  const handleCloseModalErr = () => {
    setOpenModal(false);
  };
  const { settingsValues } = useSelector((state) => state?.settings);
  const subscriptionFeeData = settingsValues?.find((settings) => settings.strName === 'SUBSCRIPTION_FEE');
  useEffect(() => {
    if (investmentLockType === 'active') {
      setSubscriptionFeePerc(0);
    } else {
      setSubscriptionFeePerc(subscriptionFeeData?.intPercentage);
    }
  }, [subscriptionFeeData]);
  useEffect(() => {
    let depositAmount = amountValue;
    let additionalAmount = depositAmount * (subscriptionFeePerc / 100);
    setProcessingAmount(additionalAmount);
    setTotalAmount(depositAmount + additionalAmount);
  }, [subscriptionFeePerc, packageData, amountValue]);
  console.log('amountValue,', amountValue);
  const handleChangePaymentMethod = (e) => {
    setTextValue(e.target.value.strName.slice(-5));
    setPaymentMethod(e.target.value);
  };
  useEffect(() => {
    setPaymentMethod(paymentQRlists[0]);
  }, [paymentQRlists]);
  const handleChangeFileTrans = (e) => {
    setErrMsg('');
    setTransFileName(e.target.files[0].name);
    const reader = new FileReader();
    reader.onload = (event) => {
      setTransBase64(event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    setTransImage(URL.createObjectURL(e.target.files[0]));
  };
  const handleClickTransRemove = () => {
    setTransFileName(null);
    setTransImage(null);
    setTransBase64('');
  };
  const handleClickSubmit = async () => {
    if (!transBase64 || !packageData) {
      if (!transBase64) {
        setErrMsg('This is required.');
      }
      if (!packageData) {
        setErrMsg('Package is required.');
      }

      return;
    }
    const data = {
      strTransProfBase64: transBase64.split(',')[1],
      strPackageId: packageData?._id,
      intCapitalAmt: amountValue,
      intTotalAmt: totalAmount,
      investmentLockType
    };
    try {
      setIsLoading(true);
      const res = await dispatch(createInvestment(data));
      setIsLoading(false);
      handleOpenModal();
    } catch (error) {
      console.log(error);
      handleOpenModalErr();
    }
  };
  const handleClickPackage = (packageItem) => {
    setPackageData(packageItem);
  };
  useEffect(() => {
    if (typeValue > 99 && typeValue < 20001) {
      const item = activePackages?.find((packages) => packages.intDepositeAmount <= typeValue && packages.intToDepositeAmount >= typeValue);
      setPackageData(item);
      setAmountValue(typeValue);
    } else {
      setPackageData(null);
      setAmountValue(0);
    }
  }, [typeValue]);
  const copyToClipboard = (text) => {
    clipboardCopy(text);
    setOpen(true);
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleChangeSwitch = (e, newValue) => {
    console.log('e', e.target.value, newValue);
    if (newValue) {
      setInvestmentLockType('active');
      setSubscriptionFeePerc(0);
    } else {
      setInvestmentLockType('inactive');
      setSubscriptionFeePerc(subscriptionFeeData?.intPercentage);
    }
  };
  return (
    <Grid container spacing={2} p={'16px'}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography>Enter your amount</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Enter your amount"
                  value={typeValue}
                  onChange={(e) => setTypeValue(e.target.value ? parseInt(e.target.value) : 0)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>Activate Plus Mode</Typography>
              <Switch
                onChange={(e, newValue) => handleChangeSwitch(e, newValue)}
                checked={investmentLockType === 'active' ? true : false}
              />
              {investmentLockType === 'active' && (
                <>
                  <Typography>Benfits - </Typography>
                  <Typography>- Locking period of 12 month</Typography>
                  <Typography>- Annually 10% Return on investment</Typography>
                  <Typography>- ⁠15 % of subscription will not be applicable</Typography>
                  <Typography>- ⁠ Access to Privilege</Typography>
                </>
              )}
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel>Choose a plan</InputLabel>
                <Select value={packageData} onChange={(e) => handleClickPackage(e.target.value)}>
                  {activePackages?.map((packageItem) => (
                    <MenuItem key={packageItem?._id} value={packageItem}>
                      {`${packageItem?.strName}  -  $${packageItem?.intDepositeAmount}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Typography variant="h3">Plan Name : {packageData?.strName}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h6">{packageData?.intDescription}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h6">Percentage Range : {packageData?.strPercentageRane}%</Typography>
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <Typography   variant="h4">
                Weekly Percentage Details :
              </Typography>
            </Grid> */}
            {/* <Grid item xs={12} md={12}>
              <TableContainer>
                <Table>
                  <TableRow>
                    <TableCell  >Monday</TableCell>
                    <TableCell  >{packageData?.intMonAmt}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell  >Tuesday</TableCell>
                    <TableCell  >{packageData?.intTueAmt}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell  >Wednesday</TableCell>
                    <TableCell  >{packageData?.intWebAmt}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell  >Thursday</TableCell>
                    <TableCell  >{packageData?.intThuAmt}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell  >Friday</TableCell>
                    <TableCell  >{packageData?.intFriAmt}%</TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </Grid> */}
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h3">Payment Details:</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h5">Capital Amount : {amountValue}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h5">Processing Amount : {processingAmount ? processingAmount : ''}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h5">Total Amount : {totalAmount ? totalAmount : ''}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography>Choose payment method:</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <Select label="Choose payment method" value={paymentMethod} onChange={(e) => handleChangePaymentMethod(e)}>
                      {paymentQRlists?.map((paymentQr) => (
                        <MenuItem key={paymentQr?._id} value={paymentQr}>
                          {paymentQr?.strName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container display={'flex'} justifyContent={'center'}>
                    <Grid item sx={{ height: '220px' }}>
                      <img src={paymentMethod?.strQRcode} alt="qrcode" style={{ height: '220px', objectFit: 'cover' }} />
                    </Grid>
                  </Grid>
                  {/* <Card sx={{ height: '260px', display: 'flex', justifyContent: 'center' }}>
                    <CardMedia
                      image={paymentMethod?.strQRcode}
                      title="Payment qr"
                      style={{ maxHeight: '100%', objectFit: 'contain', width: '70%' }}
                    />
                  </Card> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  id="outlined-adornment-password-register"
                  value={paymentMethod?.strWalletAddress}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => copyToClipboard(paymentMethod?.strWalletAddress)}
                        edge="end"
                        size="large"
                      >
                        <ContentCopyIcon style={{ color: 'black' }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert variant="filled" onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                  Copied.
                </Alert>
              </Snackbar>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography sx={{ fontSize: '.6rem', color: 'red' }}>
                Send only Tether ({textValue}) to this address. Sending any other coins may result in permanent loss. ⚠️
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={3} md={3}>
                      <Box sx={{ height: '75px', width: '75px' }}>
                        <Avatar variant="square" sx={{ width: '100%', height: 75 }}>
                          <img style={{ height: 75 }} src={transImage ? transImage : uploadIcon} alt="remy sharp" />
                        </Avatar>
                      </Box>
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          {transFileName ? (
                            <Typography>{transFileName}</Typography>
                          ) : (
                            <Typography>Choose Transaction Proof file</Typography>
                          )}
                        </Grid>

                        <Grid item xs={12} md={12}>
                          {transFileName ? (
                            <Button fullWidth variant="contained" onClick={handleClickTransRemove}>
                              Remove
                            </Button>
                          ) : (
                            <Button fullWidth variant="contained" component="label">
                              Upload
                              <input onChange={handleChangeFileTrans} hidden accept="image/*" type="file" />
                            </Button>
                          )}
                          {errMsg && (
                            <FormHelperText error id="standard-weight-helper-text-password-register">
                              {errMsg}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              {/* <Button disabled={isLoading} fullWidth variant="contained" onClick={handleClickSubmit}>
                Submit
              </Button> */}
              <Button disabled={true} fullWidth variant="contained" onClick={handleClickSubmit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <SimpleModal
        isOpen={openModal}
        onClose={handleCloseModal}
        title={'Investment Done'}
        content={'Verification might take a few hours'}
        func={onClose}
      />
      <SimpleModal isOpen={openModalErr} onClose={handleCloseModalErr} title={'Something went wrong.Try Again'} />
    </Grid>
  );
};

export default PlanCheckout;
