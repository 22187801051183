import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFaq } from 'store/faq/actions';
import HomeHeader from 'ui-component/homeheader/HomeHeader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faq = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFaq());
  }, []);
  const { faqList } = useSelector((state) => state?.faq);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <HomeHeader />
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={10} md={10}>
            {faqList?.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}a-content`}
                  id={`panel${index + 1}a-header`}
                >
                  <Typography>{item?.strQstn}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item?.strAnswer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>

          <Grid item xs={1} md={1}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Faq;
