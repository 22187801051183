import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const CustomDelayAnimWithX = ({ children, delayInSec, durInSec = 0, value }) => {
  return (
    <div>
      <motion.div
        variants={{
          hidden: { opacity: 0, x: value },
          visible: { opacity: 1, x: 0 }
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: durInSec, delay: delayInSec }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default CustomDelayAnimWithX;
