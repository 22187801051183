import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import PrivateRoute from 'guards/PrivateRoute';
import UserProfile from 'views/pages/profile/UserProfile';
import Invesments from 'views/pages/investment/Invesments';
import Wallet from 'views/pages/wallet/Wallet';
import NewInvestment from 'views/pages/investment/NewInvestment';
import WithdrawalForm from 'views/pages/withdraw/WithdrawalForm';
import Withdraw from 'views/pages/withdraw/Withdraw';
import ChatUI from 'views/pages/chat/ChatUI';
import ReferralNetwork from 'views/pages/referralnetwork/ReferralNetwork';
import ReferralNetwork2 from 'views/pages/referralnetwork/ReferralNetwork2';
import ComposeMail from 'views/pages/composemail/ComposeMail';
import SignOut from 'views/pages/signout/SignOut';
import ResetPassword from 'views/pages/resetpassword/ResetPassword';
import ResetTransPassword from 'views/pages/resetpassword/ResetTransPassword';
// import Dashboard from 'views/dashboard/CustomOne';
import Transactions from 'views/pages/transactions/Transactions';
import AssociatesMain from 'views/pages/associates/AssociatesMain';
import AssociatesUsersList from 'views/pages/associates/AssociatesUsersList';
import CapitalWithdrawWarning from 'views/pages/withdraw/CapitalWithdrawWarning';
import CapitalWithdrawalForm from 'views/pages/withdraw/CapitalWithdrawalForm';
import HelpCenterMain from 'views/pages/helpcenter/HelpCenterMain';
import TestComponent from 'views/testcomponent/TestComponent';
import TopUpInvestment from 'views/pages/investment/TopUpInvestment';
import DetailedInvestment from 'views/pages/investment/DetailedInvestment';
import NewWithdrawalForm from 'views/pages/withdraw/NewWithdrawalForm';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const Dashboard = Loadable(lazy(() => import('views/dashboard/CustomOne')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/user',
  element: (
    <PrivateRoute>
      <MainLayout />
    </PrivateRoute>
  ),
  children: [
    {
      path: '/user',
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      )
    },
    {
      path: 'dashboard',
      element: <Dashboard />
      // children: [
      //   {
      //     path: '',

      //   }
      // ]
    },

    { path: 'profile', element: <UserProfile /> },
    {
      path: 'investment',
      element: <Invesments />
    },
    {
      path: 'wallet',
      element: <Wallet />
    },
    {
      path: '/user/investment/newinvestment',
      element: <NewInvestment />
    },
    {
      path: '/user/investment/topupinvestment',
      element: <TopUpInvestment />
    },
    {
      path: '/user/investment/detailed/:investmentId',
      element: <DetailedInvestment />
    },
    {
      path: 'withdraw',
      element: <Withdraw />
    },
    {
      path: '/user/withdraw/newwithdraw',
      element: <NewWithdrawalForm />
    },
    {
      path: 'chat',
      element: <ChatUI />
    },
    {
      path: 'network',
      element: <ReferralNetwork2 />
    },
    {
      path: 'mail',
      element: <ComposeMail />
    },
    {
      path: 'signout',
      element: <SignOut />
    },
    {
      path: 'resetloginpassword',
      element: <ResetPassword />
    },
    {
      path: 'resettransactionpassword',
      element: <ResetTransPassword />
    },
    {
      path: 'transactions',
      element: <Transactions />
    },
    {
      path: 'associates',
      element: <AssociatesMain />
    },
    {
      path: '/user/associates/users',
      element: <AssociatesUsersList />
    },
    {
      path: 'capitalwithdrawal',
      element: <CapitalWithdrawWarning />
    },
    {
      path: '/user/capitalwithdrawal/newwithdrawal',
      element: <CapitalWithdrawalForm />
    },
    {
      path: 'helpcenter',
      element: <HelpCenterMain />
    },
    {
      path: 'testcomponent',
      element: <TestComponent />
    }
  ]
};

export default MainRoutes;
