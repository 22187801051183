import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, CardActions, CardContent, Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';

// project imports
import BajajAreaChartCard from './BajajAreaChartCard';
import MainCard from 'ui-component/cards/MainCard';
import SkeletonPopularCard from 'ui-component/cards/Skeleton/PopularCard';
import { gridSpacing } from 'store/constant';

// assets
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const PopularCard = ({ walletArray, walletAmount, isDashboard, isLoading }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickViewAll = () => {
    navigate('/user/wallet');
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // walletArray?.reverse();
  return (
    <>
      {isLoading ? (
        <SkeletonPopularCard />
      ) : (
        <MainCard content={false}>
          <CardContent>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <Grid container alignContent="center" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h3">Wallet</Typography>
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                        ${walletAmount ? Math.abs(walletAmount).toFixed(2) : 0}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sx={{ pt: '16px !important' }}>
                <BajajAreaChartCard />
              </Grid> */}
              <Grid item xs={12}>
                <Grid container direction="column" spacing={1}>
                  {walletArray?.length > 0 &&
                    walletArray?.slice(0, isDashboard ? 5 : walletArray.length)?.map((walletHistory) => (
                      <Grid item xs={12} md={12} key={walletHistory?._id}>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item>
                            <Typography variant="subtitle1" color="inherit">
                              {walletHistory?.strIncomeType === 'RETURNS' ? 'WITHDRAWAL' : walletHistory?.strIncomeType}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ color: 'success.dark' }}>
                              {moment(walletHistory?.strCreatdTime || walletHistory?.strCreatedTime).format(' MMMM Do YYYY, h:mm a')}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle1" color="inherit">
                              ${walletHistory?.intAmt < 0 ? -walletHistory?.intAmt?.toFixed(2) : walletHistory?.intAmt?.toFixed(2)}
                              {/* {walletHistory?.intAmt.toFixed(2)} */}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider sx={{ my: 1.5 }} />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          {isDashboard && (
            <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
              <Button onClick={handleClickViewAll} size="small" disableElevation>
                View All
                <ChevronRightOutlinedIcon />
              </Button>
            </CardActions>
          )}
        </MainCard>
      )}
    </>
  );
};

PopularCard.propTypes = {
  isLoading: PropTypes.bool
};

export default PopularCard;
