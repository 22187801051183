import React, { useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

const CustomScrollAnimation = ({ children }) => {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, rootMargin: '-50px 0px' });
  // Animate when the component is in view

  React.useEffect(() => {
    if (!isInView) {
      controls.start({ opacity: 0, y: 50 });
    }
    if (isInView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, isInView]);

  return (
    <div ref={ref}>
      <motion.div initial={{ opacity: 0, y: 50 }} animate={controls} transition={{ duration: 1 }}>
        {children}
      </motion.div>
    </div>
  );
};

export default CustomScrollAnimation;
