import React from 'react';
import { Navigation, A11y, Autoplay } from 'swiper/modules';

import { Swiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SwiperComponent = ({ children }) => {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, A11y, Autoplay]}
      spaceBetween={10}
      slidesPerView={3}
      loop={true}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false
      }}
      breakpoints={{
        // when window width is >= 320px
        0: {
          slidesPerView: 1
        },
        // when window width is >= 480px
        650: {
          slidesPerView: 2
        },
        // when window width is >= 640px
        1120: {
          slidesPerView: 3
        }
      }}
      pagination={{ el: '.swiper-pagination', clickable: true }}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }}
      // scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {children}
    </Swiper>
  );
};

export default SwiperComponent;
