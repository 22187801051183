import { AppBar, Box, Container, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import React from 'react';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import logo from 'assets/images/homepage/logo.png';
import { useNavigate } from 'react-router-dom';
import TextAnimation from 'ui-component/animations/TextAnimation';
import CustomDelayAnimation from 'ui-component/animations/CustomDelayAnimation';

const HomeHeader = () => {
  const navigate = useNavigate();
  const pages = ['Home', 'About Us', 'Privacy Policy', 'FAQ'];
  //   const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  //   const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  //   const handleOpenUserMenu = (event) => {
  //     setAnchorElUser(event.currentTarget);
  //   };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    if (page === 'Privacy Policy') {
      navigate('/privacy');
    }
    if (page === 'Home') {
      navigate('/');
    }
    if (page === 'FAQ') {
      navigate('/faq');
    }
    if (page === 'About Us') {
      navigate('/aboutus');
    }
  };
  const handkeClickImg = () => {
    navigate('/');
  };

  //   const handleCloseUserMenu = () => {
  //     setAnchorElUser(null);
  //   };
  return (
    <AppBar sx={{ bgcolor: '#030708' }} position="static">
      {/* <TextAnimation> */}
      <CustomDelayAnimation delayInSec={0.5} durInSec={1}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            {/* <img src={logo} alt="cmf" style={{ height: 40, width: 40 }} /> */}
            <Box sx={{ flexGrow: 1 }} onClick={handkeClickImg}>
              <img src={logo} alt="royalprimefx" style={{ height: 40, width: 40 }} />
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                // fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              Royal Prime Fx
            </Typography>

            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
            {/* <img src={logo} alt="cmf" style={{ height: 40, width: 40 }} /> */}
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                // mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                // fontFamily: 'monospace',
                fontWeight: 800,
                // letterSpacing: '.3rem',
                color: '#FEC260',
                textDecoration: 'none',
                justifyContent: 'center'
              }}
            >
              Royal Prime Fx
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button key={page} onClick={() => handleCloseNavMenu(page)} sx={{ my: 2, color: 'white', display: 'block' }}>
                  {page}
                </Button>
              ))}
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'right' }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' }
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </CustomDelayAnimation>
    </AppBar>
  );
};

export default HomeHeader;
