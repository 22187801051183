import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
// import WhatsAppIcon from 'assets/images/icons/whatsapp.svg';.
import WhatsappIcon from 'assets/images/icons/WhatsappIcon';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/images/homepage/logo.png';
import InstagramIcon from 'assets/images/icons/InstagramIcon';
import FacebookIcon from 'assets/images/icons/FacebookIcon';

const Footer = () => {
  const footer = [
    {
      title: 'Home',
      path: '/',
      auth: undefined
    },
    {
      title: '#888 Paper',
      path: '/#888paper',
      auth: undefined
    },
    {
      title: 'FAQ',
      path: '/faq',
      auth: undefined
    },
    {
      title: 'Privacy Policy',
      path: '/privacy',
      auth: undefined
    }
  ];
  const socialmedia = [
    {
      icon: InstagramIcon,
      link: '/'
    },
    {
      icon: WhatsappIcon,
      link: '/'
    },
    {
      icon: FacebookIcon,
      link: '/'
    }
  ];

  const navigate = useNavigate();
  const navigateToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // You can also use 'auto' for an instant scroll
    });
  };
  return (
    <Grid container sx={{ bgcolor: 'black', py: 3, px: 3 }}>
      <Grid container md={4} justifyContent="center">
        <Grid item display={'flex'} alignItems={'center'}>
          <img height="36px" width="40px" src={logo} alt="" />

          <Typography
            onClick={() => navigate('/')}
            variant="h5"
            sx={{
              fontFamily: 'Bruno Ace',
              width: '100%',
              cursor: 'pointer',
              color: 'white',
              my: 1,
              textAlign: 'center',
              fontSize: '30px',
              marginLeft: '3px'
            }}
          >
            {' '}
            | Royal Prime Fx
          </Typography>
        </Grid>

        <Typography sx={{ width: '100%', color: '#888', textAlign: 'center' }}>info@royalprimefx.com</Typography>
        {socialmedia.map((data, i) => (
          <data.icon key={i} sx={{ margin: 2 }} />
        ))}
        <Typography sx={{ width: '100%', color: '#888', my: 1, textAlign: 'center' }}>
          Privacy <span style={{ color: 'red' }}>|</span> Cookies{' '}
        </Typography>
        <Typography sx={{ width: '100%', color: '#888', textAlign: 'center' }}>Modern slavery statement</Typography>
      </Grid>

      {/* <Grid container md={4} justifyContent="center">
        <Typography variant="h5" sx={{ width: '100%', color: '#888', my: 1, textAlign: 'center' }}>
          Usefull Links
        </Typography>

        {footer.map(
          (data, i) => (
            <Grid key={i} container alignItems="start" p={1}>
              {data.path === '/#888paper' ? (
                <a href="assets/files/royalprimefx.pdf" target="_blank" style={{ width: '100%', textDecoration: 'none' }}>
                  <Typography
                    sx={{
                      width: '100%',
                      my: 1,
                      textAlign: 'center',
                      color: '#888',
                      cursor: 'pointer'
                    }}
                  >
                    {' '}
                    {data.title}
                  </Typography>
                </a>
              ) : (
                <Typography
                  onClick={() => {
                    navigate(data.path);
                    navigateToTop();
                  }}
                  sx={{
                    width: '100%',
                    my: 1,
                    textAlign: 'center',
                    color: '#888',
                    cursor: 'pointer'
                  }}
                >
                  {' '}
                  {data.title}
                </Typography>
              )}
            </Grid>
          )
          // <Typography onClick={() => router.push(data.path)} sx={{
          //     cursor: "pointer",
          //     width: "100%", color: "#888", my: 1, textAlign: "center"
          // }}>{data.title}</Typography>
        )}
      </Grid> */}

      <Grid container justifyContent="center" alignItems="center" sx={{ bgcolor: '', mt: 2 }}>
        <Divider style={{ background: '#888' }} />

        <Grid container md={8}>
          {/* <Typography
            variant="h6"
            sx={{
              width: '100%',
              color: 'gray',
              my: 1,
              textAlign: 'center',
              textDecoration: 'underline'
            }}
          >
            {' '}
            RoyalPrimeFx ©️ 2022.
          </Typography> */}

          <Typography
            sx={{
              fontSize: '0.8rem',
              width: '100%',
              color: '#888',
              my: 1,
              textAlign: 'center'
            }}
          >
            Royal Prime Fx is the Holding Company of Royal Global Investment AG. Royal Academy, Royal Global Club. Registered Address :
            HUOBMATTSTRASSE 3, LUZERN 6045 SWITZERLAND RoyalPrimeFx ©️ 2022.
          </Typography>
          <Typography
            sx={{
              fontSize: '0.8rem',
              width: '100%',
              textAlign: 'center',
              color: '#888'
            }}
          >
            LEICODE:984500C905582EM39A51
          </Typography>
          <Typography
            sx={{
              fontSize: '0.8rem',
              width: '100%',
              textAlign: 'center',
              color: '#888'
            }}
          >
            RAID : CHE-208.793.670
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
