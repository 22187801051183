import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentQRs } from 'store/paymentQR/actions';
import { getSettings } from 'store/settings/actions';
import uploadIcon from 'assets/images/users/uploadIcon.png';
import { createInvestment, createTopupInvestment } from 'store/investments/actions';
import { getPackages } from 'store/packages/actions';
import SimpleModal from 'ui-component/modals/SimpleModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import clipboardCopy from 'clipboard-copy';
import { getWalletDetails } from 'store/wallet/actions';

const TopUpInvestment = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const [subscriptionFeePerc, setSubscriptionFeePerc] = useState(0);
  const [processingAmount, setProcessingAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [errMsg, setErrMsg] = useState('');
  const [packageData, setPackageData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [walletAmount, setWalletAmount] = useState(0);
  const [typeValue, setTypeValue] = useState();
  const [amountValue, setAmountValue] = useState(0);
  const [investmentLockType, setInvestmentLockType] = useState('active');

  const { user } = useSelector((state) => state?.auth);
  useEffect(() => {
    dispatch(getWalletDetails({ _id: user._id }));
  }, []);
  const { walletDetails } = useSelector((state) => state?.wallet);
  console.log('walletDetails', walletDetails?.arrTotals[0]?.intAmt);
  useEffect(() => {
    if (walletDetails?.arrTotals[0]?.intAmt) {
      setWalletAmount(walletDetails?.arrTotals[0]?.intAmt);
    }
  }, [walletDetails?.arrTotals[0]?.intAmt]);
  useEffect(() => {
    dispatch(getSettings({ strType: 'settings', type: 'commonList' }));
  }, []);
  useEffect(() => {
    dispatch(getPackages({ restrict: 'restrict' }));
  }, []);
  const { activePackages } = useSelector((state) => state?.packages);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [openModalErr, setOpenModalErr] = useState(false);
  const handleOpenModalErr = () => {
    setOpenModal(true);
  };
  const handleCloseModalErr = () => {
    setOpenModal(false);
  };
  const { settingsValues } = useSelector((state) => state?.settings);
  const subscriptionFeeData = settingsValues?.find((settings) => settings.strName === 'SUBSCRIPTION_FEE');
  useEffect(() => {
    if (investmentLockType === 'active') {
      setSubscriptionFeePerc(0);
    } else {
      setSubscriptionFeePerc(subscriptionFeeData?.intPercentage);
    }
  }, [subscriptionFeeData]);
  useEffect(() => {
    let depositAmount = amountValue;
    let additionalAmount = depositAmount * (subscriptionFeePerc / 100);
    setProcessingAmount(additionalAmount);
    setTotalAmount(depositAmount + additionalAmount);
  }, [subscriptionFeePerc, packageData, amountValue]);

  const handleClickSubmit = async () => {
    if (!packageData) {
      setErrMsg('Package is required.');
      return;
    }

    const data = {
      //   strTransProfBase64: transBase64.split(',')[1],
      strPackageId: packageData?._id,
      intCapitalAmt: amountValue,
      intTotalAmt: totalAmount,
      investmentLockType
    };
    try {
      setIsLoading(true);
      console.log('data', data);
      const res = await dispatch(createTopupInvestment(data));
      setIsLoading(false);
      handleOpenModal();
    } catch (error) {
      console.log(error);
      handleOpenModalErr();
    }
  };
  const handleClickPackage = (packageItem) => {
    setPackageData(packageItem);
  };
  const copyToClipboard = (text) => {
    clipboardCopy(text);
    setOpen(true);
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    if (typeValue > 99 && typeValue < 20001) {
      const item = activePackages?.find((packages) => packages.intDepositeAmount <= typeValue && packages.intToDepositeAmount >= typeValue);
      setPackageData(item);
      setAmountValue(typeValue);
    } else {
      setPackageData(null);
      setAmountValue(0);
    }
  }, [typeValue]);
  console.log('activePackages', activePackages);
  const handleChangeSwitch = (e, newValue) => {
    console.log('e', e.target.value, newValue);
    if (newValue) {
      setInvestmentLockType('active');
      setSubscriptionFeePerc(0);
    } else {
      setInvestmentLockType('inactive');
      setSubscriptionFeePerc(subscriptionFeeData?.intPercentage);
    }
  };

  return (
    <Grid container spacing={2} p={'16px'}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography>Enter your amount</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  label="Enter your amount"
                  value={typeValue}
                  onChange={(e) => setTypeValue(e.target.value ? parseInt(e.target.value) : 0)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>Activate Plus Mode</Typography>
              <Switch
                onChange={(e, newValue) => handleChangeSwitch(e, newValue)}
                checked={investmentLockType === 'active' ? true : false}
              />
              {investmentLockType === 'active' && (
                <>
                  <Typography>Benfits - </Typography>
                  <Typography>- Locking period of 12 month</Typography>
                  <Typography>- Annually 10% Return on investment</Typography>
                  <Typography>- ⁠15 % of subscription will not be applicable</Typography>
                  <Typography>- ⁠ Access to Privilege</Typography>
                </>
              )}
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel>Choose a plan</InputLabel>
                <Select value={packageData} onChange={(e) => handleClickPackage(e.target.value)}>
                  {activePackages?.map((packageItem) => (
                    <MenuItem
                      disabled={packageItem.intDepositeAmount + (packageItem.intDepositeAmount * subscriptionFeePerc) / 100 > walletAmount}
                      key={packageItem?._id}
                      value={packageItem}
                    >
                      {`${packageItem?.strName}  -  $${packageItem?.intDepositeAmount}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Typography variant="h3">Plan Name : {packageData?.strName}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h6">{packageData?.intDescription}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h6">Percentage Range : {packageData?.strPercentageRane}%</Typography>
            </Grid>
            {/* <Grid item xs={12} md={12}>
                <Typography   variant="h4">
                  Weekly Percentage Details :
                </Typography>
              </Grid> */}
            {/* <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table>
                    <TableRow>
                      <TableCell  >Monday</TableCell>
                      <TableCell  >{packageData?.intMonAmt}%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell  >Tuesday</TableCell>
                      <TableCell  >{packageData?.intTueAmt}%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell  >Wednesday</TableCell>
                      <TableCell  >{packageData?.intWebAmt}%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell  >Thursday</TableCell>
                      <TableCell  >{packageData?.intThuAmt}%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell  >Friday</TableCell>
                      <TableCell  >{packageData?.intFriAmt}%</TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid> */}
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h3">Payment Details:</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h5">Capital Amount : {amountValue}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h5">Processing Amount : {processingAmount ? processingAmount : ''}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h5">Total Amount : {totalAmount ? totalAmount : ''}</Typography>
            </Grid>
            {errMsg && (
              <FormHelperText error id="standard-weight-helper-text-password-register">
                {errMsg}
              </FormHelperText>
            )}
            <Grid item xs={12} md={12}>
              <Button disabled={isLoading} fullWidth variant="contained" onClick={handleClickSubmit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <SimpleModal
        isOpen={openModal}
        onClose={handleCloseModal}
        title={'Investment Done'}
        content={'Verification might take a few hours'}
        navigateUrl={'/user/dashboard'}
      />
      <SimpleModal isOpen={openModalErr} onClose={handleCloseModalErr} title={'Something went wrong.Try Again'} />
    </Grid>
  );
};

export default TopUpInvestment;
