import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getInvestment } from 'store/investments/actions';
import CustomDrawer from 'ui-component/drawer/CustomDrawer';
import PlanCheckout from './PlanCheckout';
import moment from 'moment';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme, styled } from '@mui/material/styles';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: '50%',
    top: -30,
    right: -180
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
    borderRadius: '50%',
    top: -160,
    right: -130
  }
}));

const Invesments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [investmentStatus, setInvestmentStatus] = useState('P');
  const handleChange = (event) => {
    setInvestmentStatus(event.target.value);
  };
  const handleOpen = () => {
    setOpenDrawer(true);
  };
  const handleClose = () => {
    setOpenDrawer(false);
  };
  const { user } = useSelector((state) => state?.auth);
  useEffect(() => {
    dispatch(getInvestment({ strUserId: user?._id, chrStatus: investmentStatus }));
  }, [investmentStatus]);
  const { myInvestments } = useSelector((state) => state?.investments);
  const myOnlyInvestments = myInvestments?.filter((investment) => investment?.strUserId === user?._id);
  useEffect(() => {
    if (myOnlyInvestments.length > 0) {
      let total = 0;
      for (let i = 0; i < myOnlyInvestments.length; i++) {
        total += myOnlyInvestments[i]?.intCapitalAmt;
      }
      setTotalAmount(total);
    }
    if (myOnlyInvestments.length === 0) {
      setTotalAmount(0);
    }
  }, [myOnlyInvestments]);
  const handleClickNewInvestment = () => {
    // navigate('/user/investment/newinvestment');
    handleOpen();
  };
  const handleClickInvestment = (id) => {
    navigate(`/user/investment/detailed/${id}`);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Investments</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} display={'flex'} justifyContent={'right'}>
        <Button variant="contained" onClick={handleClickNewInvestment}>
          New investment
        </Button>
      </Grid>

      <Grid item xs={12} md={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={investmentStatus}
            label="Status"
            onChange={handleChange}
          >
            <MenuItem value="P">Pending</MenuItem>
            <MenuItem value="Q">Withdrawal Initiated</MenuItem>
            <MenuItem value="N">Approved</MenuItem>
            <MenuItem value="R">Withdrawn Investments</MenuItem>
            <MenuItem value="S">Rejected</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} md={12}>
        <Grid container>
          <ToggleButtonGroup
            sx={{ backgroundColor: 'white' }}
            color="primary"
            value={investmentStatus}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="P">Pending</ToggleButton>
            <ToggleButton value="Q">Withdrawal Initiated</ToggleButton>
            <ToggleButton value="N">Approved</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid container>
          <ToggleButtonGroup
            sx={{ backgroundColor: 'white' }}
            color="primary"
            value={investmentStatus}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="R">Withdrawn Investments </ToggleButton>
            <ToggleButton value="S">Rejected</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid> */}
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: '16px' }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  {/* <TableContainer sx={{ width: '100%' }}>
                    <TableBody>
                      {myInvestments?.map((investment, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            <Typography   variant="h3">
                              {investment?.objPackage[0]?.strName}
                            </Typography>
                            <Typography  >{investment?.strCreatedTime}</Typography>
                          </TableCell>
                          <TableCell  >{investment?.intCapitalAmt}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </TableContainer> */}

                  <CardWrapper sx={{ padding: 2 }} border={false} content={false}>
                    <Grid container>
                      <Grid item xs={10} display={'flex'} alignItems={'center'}>
                        <Typography variant="h4">Total</Typography>
                      </Grid>
                      <Grid item xs={2} display={'flex'} alignItems={'center'}>
                        <Typography variant="h3">${totalAmount ? totalAmount : 0}</Typography>
                      </Grid>
                    </Grid>
                  </CardWrapper>
                  {myOnlyInvestments?.map((investment, i) => (
                    <Card key={i} sx={{ padding: '16px' }} onClick={() => handleClickInvestment(investment._id)}>
                      <Grid container>
                        <Grid item xs={9} md={9}>
                          <Typography variant="h3">{investment?.objPackage[0]?.strName}</Typography>
                          <Typography>{moment(investment?.strCreatedTime).format(' MMMM Do YYYY, h:mm a')}</Typography>
                        </Grid>
                        <Grid item xs={3} md={3} display={'flex'} alignItems={'center'} justifyContent={'right'}>
                          <Typography variant="h3">{investment?.intCapitalAmt}</Typography>
                        </Grid>
                        {investment?.strRejectMsg && (
                          <Grid item xs={3} md={3} display={'flex'} alignItems={'center'} justifyContent={'right'}>
                            <Typography>Reason : {investment?.strRejectMsg}</Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Card>
                  ))}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
      </Grid>
      <CustomDrawer open={openDrawer} onClose={handleClose} title={'Plan Checkout'}>
        <PlanCheckout onClose={handleClose} />
      </CustomDrawer>
    </Grid>
  );
};

export default Invesments;
