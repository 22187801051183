import { Button, Card, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CapitalWithdrawWarning = () => {
  const navigate = useNavigate();
  const [confirmation, setConfirmation] = useState(false);
  const [daysConfirmation, setDaysConfirmation] = useState(false);
  const handleChangeConfirmation = () => {
    setConfirmation(!confirmation);
  };
  const handleChange90DaysConfirmation = () => {
    setDaysConfirmation(!daysConfirmation);
  };
  const handleClickContinue = () => {
    navigate('/user/capitalwithdrawal/newwithdrawal');
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Capital Withdrawal</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Card variant="outlined" sx={{ padding: '8px', backgroundColor: '#ffd8d8' }}>
                <Typography>
                  If you withdraw your capital , your account will be disabled and you will not able to use any features like Daily profit ,
                  refferal income or anykind of salary etc. If you want use our features you should be a member with a minimum investment
                  within 1 month of time period otherwise your account will be terminated permenently.
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography> Are you sure in capital withdrawal</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={confirmation}
                        onChange={() => handleChangeConfirmation()}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={!confirmation}
                        onChange={() => handleChangeConfirmation()}
                      />
                    }
                    label="No"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography>Agree service fee deduction of 10% if your withdrawal before 90 days</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={daysConfirmation}
                        onChange={() => handleChange90DaysConfirmation()}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={!daysConfirmation}
                        onChange={() => handleChange90DaysConfirmation()}
                      />
                    }
                    label="No"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button disabled={!confirmation || !daysConfirmation} variant="contained" onClick={handleClickContinue}>
                Continue
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CapitalWithdrawWarning;
