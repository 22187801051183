import { Button, Card, CardContent, CardMedia, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import card1Img from 'assets/images/homepage/IMG_8132.jpg';
import card2Img from 'assets/images/homepage/IMG_8133.jpg';
import card3Img from 'assets/images/homepage/IMG_8134.jpg';
import card4Img from 'assets/images/homepage/IMG_8135.jpg';
import CustomScrollAnimation from 'ui-component/animations/CustomScrollAnimation';
import FromLeftAnim from 'ui-component/animations/FromLeftAnim';

const styleHeading = {
  color: '#E2FFD3',
  fontSize: '22px',
  fontWeight: 700,
  textAlign: 'center'
};
const styleContent = {
  color: '#E2FFD3',
  fontSize: '16px'
};
const buttonStyle = { border: 'solid 3px white', borderRadius: '25px', backgroundColor: '#030708', marginTop: '5px' };

const StaticCards = () => {
  const theme = useTheme();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid container spacing={3} sx={{ paddingBottom: '30px', display: 'flex' }}>
      <Grid item xs={12} md={3}>
        <CustomScrollAnimation>
          <Card
            style={{
              borderRadius: '10px',
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${card1Img})`,
              backgroundSize: 'cover',
              backgroundPosition: 'top'
              // backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
          >
            {/* <CardMedia sx={{ maxHeight: 240, objectFit: 'cover', objectPosition: 'top' }} component="img" image={card1Img} alt="" /> */}
            <CardContent sx={{ minHeight: matchDownSM ? 0 : 180 }}>
              <FromLeftAnim value={-50}>
                <Typography sx={styleHeading}>FOREX TRADING</Typography>
                <Typography sx={styleContent}>
                  Master the art of Forex trading with our comprehensive education. Gain essential skills and insights to navigate the
                  global currency markets successfully.
                </Typography>
                <Button
                  // onClick={handleClickLogin}
                  style={{ border: 'solid 3px white', borderRadius: '25px', backgroundColor: '#030708', marginTop: '5px' }}
                  variant="contained"
                >
                  Know More
                </Button>
              </FromLeftAnim>
            </CardContent>
          </Card>
        </CustomScrollAnimation>
      </Grid>

      <Grid item xs={12} md={3} sx={{ height: '100%' }}>
        <CustomScrollAnimation>
          <Card
            style={{
              borderRadius: '10px',
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${card2Img})`,
              backgroundSize: 'cover',
              objectPosition: 'top'
              // backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
          >
            {/* <CardMedia sx={{ maxHeight: 240, objectFit: 'cover', objectPosition: 'top' }} component="img" image={card2Img} alt="" /> */}
            <CardContent sx={{ minHeight: matchDownSM ? 0 : 180 }}>
              <FromLeftAnim value={50}>
                <Typography sx={styleHeading}>REALVERSE HUB</Typography>
                <Typography sx={styleContent}>
                  REALVERSE HUB are your online virtual space to express your creativity, host events, hang out with friend and buying
                  selling of properties.
                </Typography>
                <Button
                  // onClick={handleClickLogin}
                  style={{ border: 'solid 3px white', borderRadius: '25px', backgroundColor: '#030708', marginTop: '5px' }}
                  variant="contained"
                >
                  Know More
                </Button>
              </FromLeftAnim>
            </CardContent>
          </Card>
        </CustomScrollAnimation>
      </Grid>
      <Grid item xs={12} md={3}>
        <CustomScrollAnimation>
          <Card
            style={{
              borderRadius: '10px',
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${card3Img})`,
              backgroundSize: 'cover',
              objectPosition: 'top'
              // backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
          >
            {/* <CardMedia
              sx={{ maxHeight: 240, minHeight: 240, objectFit: 'cover', objectPosition: 'top', transform: 'scale(1.1)' }}
              component="img"
              image={card3Img}
              alt=""
            /> */}
            <CardContent sx={{ minHeight: matchDownSM ? 0 : 180 }}>
              <FromLeftAnim value={-50}>
                <Typography sx={styleHeading}>METAVERSE</Typography>
                <Typography sx={styleContent}>
                  The metaverse: a digital realm where virtual and physical realities converge, fostering immersive experiences and
                  interconnected interactions for users worldwide.
                </Typography>
                <Button
                  // onClick={handleClickLogin}
                  style={{ border: 'solid 3px white', borderRadius: '25px', backgroundColor: '#030708', marginTop: '5px' }}
                  variant="contained"
                >
                  Know More
                </Button>
              </FromLeftAnim>
            </CardContent>
          </Card>
        </CustomScrollAnimation>
      </Grid>
      <Grid item xs={12} md={3}>
        <CustomScrollAnimation>
          <Card
            style={{
              borderRadius: '10px',
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${card4Img})`,
              backgroundSize: 'cover',
              objectPosition: 'top'
              // backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
          >
            {/* <CardMedia sx={{ maxHeight: 240, objectFit: 'cover', objectPosition: 'top' }} component="img" image={card4Img} alt="" /> */}
            <CardContent sx={{ minHeight: matchDownSM ? 0 : 180 }}>
              <FromLeftAnim value={50}>
                <Typography sx={styleHeading}>BLOCKCHAIN DEVELOPMENT WORKSHOP</Typography>
                <Typography sx={styleContent}>
                  Unlock the potential of blockchain with our hands-on Development Workshop. Learn to build the future of decentralized
                  applications in a dynamic and immersive environment.
                </Typography>
                <Button
                  // onClick={handleClickLogin}
                  style={{ border: 'solid 3px white', borderRadius: '25px', backgroundColor: '#030708', marginTop: '5px' }}
                  variant="contained"
                >
                  Know More
                </Button>
              </FromLeftAnim>
            </CardContent>
          </Card>
        </CustomScrollAnimation>
      </Grid>
    </Grid>
  );
};

export default StaticCards;
