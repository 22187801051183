import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';

// third-party
import Chart from 'react-apexcharts';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonTotalOrderCard from 'ui-component/cards/Skeleton/EarningCard';

import ChartDataMonth from '../dashboard/CustomOne/chart-data/total-order-month-line-chart';
import ChartDataYear from '../dashboard/CustomOne/chart-data/total-order-year-line-chart';

// assets
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CurvedSplit from './CurvedSplit';
import TeampoolCard from './TeampoolCard';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: '#f7ef8a',
  color: '#000',
  overflow: 'hidden',
  position: 'relative',
  '&>div': {
    position: 'relative',
    zIndex: 5
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: '#AE8625',
    borderRadius: '50%',
    zIndex: 1,
    top: -85,
    left: -95,
    [theme.breakpoints.down('sm')]: {
      top: -105,
      right: -140
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    width: 210,
    height: 210,
    background: '#AE8625',
    borderRadius: '50%',
    top: -125,
    left: -15,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: -155,
      right: -70
    }
  }
}));
// const useStyles = makeStyles({
//   card: {
//     position: 'relative',
//     minHeight: 200,
//     display: 'flex',
//     flexDirection: 'column',
//     overflow: 'hidden',
//     borderRadius: '8px' // Adjust the border radius as needed
//   },
//   upperHalf: {
//     flex: 1,
//     backgroundColor: '#FFD700', // Golden color
//     borderTopLeftRadius: '8px', // Same as the card's border radius
//     borderTopRightRadius: '8px' // Same as the card's border radius
//   },
//   lowerHalf: {
//     flex: 1,
//     backgroundColor: '#yourSecondColor', // Color above the diagonal line
//     borderBottomLeftRadius: '8px', // Same as the card's border radius
//     borderBottomRightRadius: '8px' // Same as the card's border radius
//   }
// });

const TestComponent = () => {
  const theme = useTheme();
  const [timeValue, setTimeValue] = useState(false);
  // const classes = useStyles();
  return (
    <>
      {/* <Card className={classes.card}>
        <div className={classes.upperHalf}></div>
        <div className={classes.lowerHalf}></div>
        <CardContent>
          <h2>Your Card Content</h2>
        </CardContent>
      </Card> */}

      {/* <div style={{ display: 'flex', height: '200px' }}>
          <div style={{ flex: '1', background: 'red', borderTopRightRadius: '50%' }}></div>
          <div style={{ flex: '1', background: 'yellow' }}></div>
        </div> */}
      {/* <CurvedSplit /> */}
      {/* <div style={{ background: 'linear-gradient(to bottom left, red 50%, yellow 50%)', height: '200px' }}></div> */}
      {/* <div
        style={{ background: 'radial-gradient(circle at top left, transparent 25%, red 25%, red 50%, yellow 50%)', height: '200px' }}
      ></div> */}
      <TeampoolCard indirectReferralInvestment={2000} />
    </>
  );
};

export default TestComponent;
