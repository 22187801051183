import api from 'utils/api';
import { GET_PAYMENT_QRS } from './constants';

export const getPaymentQRs = (body) => async (dispatch) => {
  try {
    const { data } = await api.post('/common_api', body);
    dispatch({
      type: GET_PAYMENT_QRS,
      payload: data?.arrList
    });
  } catch (error) {
    console.log(error);
  }
};
