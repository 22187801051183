import { GET_ADMIN_LIST, GET_ALL_TRANSACTION, GET_USER_BY_ID, LOGIN_SUCCESS, LOGIN_UNSUCCESS, LOGOUT_SUCCESS } from './constants';

const initialState = {
  isLoggedIn: false,
  user: {},
  adminList: [],
  transactionList: [],
  transactionsCount: null
};
export default function auth(state = initialState, { type, payload }) {
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: payload.loginSuccess,
        user: payload.userDetails
      };
    case LOGIN_UNSUCCESS:
      return {
        ...state,
        isLoggedIn: payload
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: payload
      };
    case GET_ADMIN_LIST:
      return {
        ...state,
        adminList: payload
      };
    case GET_ALL_TRANSACTION:
      return {
        ...state,
        transactionList: payload?.arrList,
        transactionsCount: payload?.count
      };
    case GET_USER_BY_ID:
      return {
        ...state,
        user: payload
      };
    default:
      return state;
  }
}
