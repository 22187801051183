import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  NativeSelect,
  Select,
  Typography
} from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTransaction } from 'store/auth/actions';
import moment from 'moment';
import { useState } from 'react';
import CustomPagination from 'ui-component/CustomPagination';

const Transactions = () => {
  const [filter, setFilter] = useState('a');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const dispatch = useDispatch();
  useEffect(() => {
    if (filter === 'a') {
      dispatch(getAllTransaction({ strType: null, page: page, limit: limit }));
    } else {
      dispatch(getAllTransaction({ strType: filter, page: page, limit: limit }));
    }
  }, [filter, page, limit]);
  const { transactionList, transactionsCount } = useSelector((state) => state?.auth);
  console.log(transactionList);
  const handleChangeFilter = (e) => {
    setFilter(e.target.value);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ p: '16px' }}>
          <Typography variant="h3">Transaction History</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Filter</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={filter}
                  label="Filter"
                  onChange={handleChangeFilter}
                >
                  <MenuItem value={'a'}>All</MenuItem>
                  <MenuItem value={'INVESTMENT AMOUNT'}>Investments</MenuItem>
                  <MenuItem value={'INVESTMENT SERVICE FEE'}>Investment Service Amount</MenuItem>
                  <MenuItem value={'PROFIT'}>Profit</MenuItem>
                  <MenuItem value={'REFERAL'}>Referral</MenuItem>
                  <MenuItem value={'RETURN'}>Withdraw</MenuItem>
                  <MenuItem value={'SALARY_CREDIT'}>Milestone Salary</MenuItem>
                  <MenuItem value={'INFINITY_SALARY_CREDIT'}>Infinity Salary</MenuItem>
                  <MenuItem value={'PRIME_PROFIT'}>Prime Profit</MenuItem>
                  <MenuItem value={'TOPUP FEE'}>Topup Fee</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          <Card sx={{ p: '16px' }}>
            <Grid container spacing={2}>
              {transactionList?.map((transaction) => (
                <Grid item xs={12} md={12} key={transaction?._id}>
                  <Grid container>
                    <Grid item xs={10} md={10}>
                      {/* <Typography>{transaction.strType || transaction.strIncomeType}</Typography> */}
                      <Typography variant="h4">{transaction?.strStatment || transaction?.strStatement}</Typography>
                      <Typography>
                        {transaction?.strCreatedTime
                          ? moment(transaction?.strCreatedTime).format(' MMMM Do YYYY, h:mm a')
                          : transaction?.strCreatedTime}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={2} display={'flex'} alignItems={'center'} justifyContent={'right'}>
                      <Typography variant="h4" style={{ color: transaction.intTransType === 'Debit' ? 'red' : 'green' }}>
                        {transaction.intTransType === 'Debit' ? '-' : ''}${Math.abs(transaction.intAmt).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2 }} />
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
        {/* <Container maxWidth="md" sx={{ padding: 0 }}>
          {transactionList?.map((transaction) => (
            <Card key={transaction._id} elevation={3} style={{ marginBottom: '20px' }}>
              <CardHeader
                sx={{ p: 2 }}
                title={transaction.strType || transaction.strIncomeType}
                subheader={moment(transaction.strCreatdTime).format(' MMMM Do YYYY, h:mm a')}
              />
              <List>
                <ListItem>
                  <div style={{ flex: 1 }}>
                    <Typography variant="body1">{transaction?.strStatment || transaction?.strStatement}</Typography>
                  </div>
                  <ListItemSecondaryAction>
                    {transaction?.intTransType === 'Debit' ? '-' : ''}${transaction?.intAmt}
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Card>
          ))}
        </Container> */}
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomPagination count={transactionsCount} page={page} rowsPerPage={limit} setRowsPerPage={setLimit} onPageChange={setPage} />
      </Grid>
    </Grid>
  );
};

export default Transactions;
