import { Avatar, Box, Button, CircularProgress, FormHelperText, Grid, Typography } from '@mui/material';
import React from 'react';
import uploadIcon from 'assets/images/users/uploadIcon.png';
import { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { updateProofIds } from 'store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';

const IdProofUpload = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fileImage, setFileImage] = useState(null);
  const [fileImageTwo, setFileImageTwo] = useState(null);
  const { user } = useSelector((state) => state?.auth);
  const validationSchema = Yup.object().shape({
    strIDBase64: Yup.string().required('ID proof is required').nullable(),
    strIdProof2Base64: Yup.string().required('ID proof is required').nullable()
  });
  const formik = useFormik({
    initialValues: {
      strIDBase64: '',
      strIdProof2Base64: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (values.strIDBase64) {
        values.strIDBase64 = values.strIDBase64.split(',')[1] ? values.strIDBase64.split(',')[1] : values.strIDBase64;
      }
      if (values.strIdProof2Base64) {
        values.strIdProof2Base64 = values.strIdProof2Base64.split(',')[1]
          ? values.strIdProof2Base64.split(',')[1]
          : values.strIdProof2Base64;
      }
      values.strKYCStatus = 'NA';
      values._id = user?._id;
      console.log(values);
      setIsLoading(true);
      await dispatch(updateProofIds(values, { strUserId: user?._id }));
      setIsLoading(false);
    }
  });

  const handleClickFileRemove = () => {
    setFileImage(null);
    setFileName(null);
    setFieldValue('strIDBase64', null);
  };
  const handleClickFileRemove2 = () => {
    setFileImageTwo(null);
    setFileName2(null);
    setFieldValue('strIdProof2Base64', null);
  };
  const handleChangeFile = (e) => {
    // setFile(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = (event) => {
      setFieldValue('strIDBase64', event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    setFileImage(URL.createObjectURL(e.target.files[0]));
  };
  const handleChangeFile2 = (e) => {
    // setFile(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = (event) => {
      setFieldValue('strIdProof2Base64', event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    setFileImageTwo(URL.createObjectURL(e.target.files[0]));
  };
  const fileInputRef = useRef();
  const handleButtonClickBox = () => {
    fileInputRef.current.click();
  };
  const { handleSubmit, touched, errors, values, setFieldValue, handleReset } = formik;
  return (
    <Grid container>
      {isLoading ? (
        <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
          <CircularProgress />
        </Grid>
      ) : (
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography>Reupload Id proof</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Box sx={{ height: '75pxpx', width: '75px' }}>
                          <Avatar variant="square" sx={{ width: '100%', height: 75 }}>
                            {/* <UploadFileIcon sx={{ height: 100 }} /> */}
                            <img style={{ height: 75 }} src={fileImage ? fileImage : uploadIcon} alt="remy sharp" />
                          </Avatar>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Grid container spacing={2}>
                          {/* <Grid item xs={12} md={12}>
                          {fileName ? <Typography>{fileName}</Typography> : <Typography>Choose ID proof file *</Typography>}
                        </Grid> */}
                          <Grid item xs={12} md={12}>
                            {fileImage ? (
                              <Button fullWidth variant="contained" onClick={handleClickFileRemove}>
                                Remove
                              </Button>
                            ) : (
                              <Button fullWidth variant="contained" component="label">
                                Reupload
                                <input onChange={handleChangeFile} hidden accept="image/*" type="file" />
                              </Button>
                            )}
                          </Grid>
                        </Grid>

                        {touched.strIDBase64 && errors.strIDBase64 && (
                          <FormHelperText error id="standard-weight-helper-text-password-register">
                            {errors.strIDBase64}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Box
                          sx={{ height: '75pxpx', width: '75px', cursor: fileImageTwo ? 'default' : 'pointer' }}
                          onClick={handleButtonClickBox}
                        >
                          <input onChange={handleChangeFile2} ref={fileInputRef} hidden accept="image/*" type="file" />
                          <Avatar variant="square" sx={{ width: '100%', height: 75 }}>
                            {fileImageTwo ? (
                              <>
                                <img style={{ height: 75 }} src={fileImageTwo} alt="remy sharp" />
                                <CloseIcon
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    cursor: 'pointer',
                                    backgroundColor: 'white',
                                    borderRadius: '50%'
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent the parent's click event from firing
                                    // Handle the close icon click event here
                                    // You can clear the fileImageTwo or perform any other action
                                    handleClickFileRemove2();
                                  }}
                                />
                              </>
                            ) : (
                              <img style={{ height: 75 }} src={uploadIcon} alt="remy sharp" />
                            )}
                          </Avatar>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        {/* <Grid container spacing={2}>
                        
                        <Grid item xs={12} md={12}>
                          {fileImageTwo ? (
                            <Button fullWidth variant="contained" onClick={handleClickFileRemove2}>
                              Remove
                            </Button>
                          ) : (
                            <Button fullWidth variant="contained" component="label">
                              Upload
                              <input onChange={handleChangeFile2} hidden accept="image/*" type="file" />
                            </Button>
                          )}
                        </Grid>
                      </Grid> */}

                        {touched.strIdProof2Base64 && errors.strIdProof2Base64 && (
                          <FormHelperText error id="standard-weight-helper-text-password-register">
                            {errors.strIdProof2Base64}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </Grid>
  );
};

export default IdProofUpload;
