// assets
import { IconBrandChrome, IconHelp, IconDashboard } from '@tabler/icons';
// constant
const icons = { IconBrandChrome, IconHelp, IconDashboard };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: 'sample-docs-roadmap',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Dashboard',
      type: 'item',
      url: '/user/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'profile',
      title: 'Profile',
      type: 'item',
      url: '/user/profile',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },

    {
      id: 'investment',
      title: 'Investment',
      type: 'item',
      url: '/user/investment',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },

    {
      id: 'withdraw',
      title: 'Withdrawal',
      type: 'item',
      url: '/user/withdraw',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'wallet',
      title: 'Wallet',
      type: 'item',
      url: '/user/wallet',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    // {
    //   id: 'network',
    //   title: 'Associates',
    //   type: 'item',
    //   url: '/user/network',
    //   icon: icons.IconBrandChrome,
    //   breadcrumbs: false
    // },
    {
      id: 'associates',
      title: 'Associates',
      type: 'item',
      url: '/user/associates',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'support',
      title: 'Support',
      type: 'collapse',
      icon: icons.IconBrandChrome,
      children: [
        {
          id: 'chat',
          title: 'Chat',
          type: 'item',
          url: '/user/chat',
          icon: icons.IconDashboard,
          breadcrumbs: false
        },
        {
          id: 'mail',
          title: 'Mail',
          type: 'item',
          url: '/user/mail',
          icon: icons.IconDashboard,
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'transaction history',
      title: 'Transaction History',
      type: 'item',
      url: '/user/transactions',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'helpcenter',
      title: 'Account Center',
      type: 'item',
      url: '/user/helpcenter',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'signout',
      title: 'SignOut',
      type: 'item',
      url: '/user/signout',
      icon: icons.IconDashboard,
      breadcrumbs: false
    }
  ]
};

export default other;
