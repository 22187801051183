import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Grid,
  Card,
  Typography
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import api from 'utils/api';
import { getSettings } from 'store/settings/actions';
import { getWalletDetails } from 'store/wallet/actions';

function UserRow({ userSub, count, setCount }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [subUsers, setSubUsers] = useState([]);
  const { user } = useSelector((state) => state?.auth);
  const [referalIncome3LevelsLimit, setReferalIncome3LevelsLimit] = useState(0);

  useEffect(() => {
    dispatch(getSettings({ strType: 'settings', type: 'commonList' }));
  }, []);
  const { settingsValues } = useSelector((state) => state?.settings);
  useEffect(() => {
    setReferalIncome3LevelsLimit(settingsValues[4]?.intInvestmentLimit);
  }, [settingsValues]);
  useEffect(() => {
    dispatch(getWalletDetails({ _id: user._id }));
  }, []);
  const { walletDetails } = useSelector((state) => state?.wallet);
  const capitalAmount = walletDetails?.arrCapitals[0]?.intAmt ? walletDetails?.arrCapitals[0]?.intAmt : 0;
  const handleToggle = async () => {
    if (!open) {
      try {
        let subUsersLimit = 3; // Default limit for subusers
        console.log(capitalAmount, 'referalIncome3LevelsLimit', referalIncome3LevelsLimit);
        if (capitalAmount > referalIncome3LevelsLimit) {
          subUsersLimit = 10; // Increase the limit for users with capitalamount greater than certainAmount
        }

        if (count > subUsersLimit) {
          return;
        }
        const response = await api.post('/get_ref_legs', {
          strRefCode: userSub.strRefCode
          //   limit: subUsersLimit // Send the limit to the API
        });

        if (response.data.success) {
          setSubUsers(response.data.arrList);
          setCount(count + 1);
          setOpen(true);
        }
      } catch (error) {
        console.error('Error fetching sub-users:', error);
      }
    } else {
      setOpen(false);
      setSubUsers([]);
    }
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>{userSub.strName}</TableCell>
        <TableCell>{userSub.strMobileNo}</TableCell>
        <TableCell>{userSub.strEmail}</TableCell>
        <TableCell sx={{ fontWeight: '700' }}>
          {/* {userSub.arrInvestments?.map((investment) => investment?.intCapitalAmt).reduce((acc, currentValue) => acc + currentValue)}
           */}
          ${Math.abs(userSub.arrInvestMents.reduce((total, investment) => total + investment.intCapitalAmt, 0)).toFixed(2)}
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => handleToggle()}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>UserName</TableCell>
                    <TableCell>Mobile No</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Investments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subUsers.map((subUser) => (
                    <UserRow key={subUser._id} userSub={subUser} count={count} setCount={setCount} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function ReferralNetwork2() {
  const dispatch = useDispatch();
  const [rootUsers, setRootUsers] = useState([]);
  const { user } = useSelector((state) => state?.auth);
  const [count, setCount] = useState(2);
  //   const [referalIncome3LevelsLimit, setReferalIncome3LevelsLimit] = useState(0);

  //   useEffect(() => {
  //     dispatch(getSettings({ strType: 'settings', type: 'commonList' }));
  //   }, []);
  //   const { settingsValues } = useSelector((state) => state?.settings);
  //   useEffect(() => {
  //     setReferalIncome3LevelsLimit(settingsValues[4]?.intInvestmentLimit);
  //   }, [settingsValues]);
  useEffect(() => {
    // Simulate initial API call for the root users
    async function fetchRootUsers() {
      try {
        const response = await api.post('/get_ref_legs', {
          strRefCode: user?.strRefCode
        });

        if (response.data.success) {
          setRootUsers(response.data.arrList);
        }
      } catch (error) {
        console.error('Error fetching root users:', error);
      }
    }

    fetchRootUsers();
  }, [user]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Affiliate Member</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Userame</TableCell>
                <TableCell>Mobile No</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Investments</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rootUsers.map((rootUser) => (
                <UserRow key={rootUser._id} userSub={rootUser} count={count} setCount={setCount} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default ReferralNetwork2;
