import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import auth from './auth/reducer';
import packages from './packages/reducer';
import investments from './investments/reducer';
import paymentQR from './paymentQR/reducer';
import settings from './settings/reducer';
import withdraw from './withdraw/reducer';
import wallet from './wallet/reducer';
import mileStone from './milestone/reducer';
import chat from './chat/reducer';
import faq from './faq/reducer';
import helpers from './helpers/reducer';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  auth,
  packages,
  investments,
  paymentQR,
  settings,
  withdraw,
  wallet,
  mileStone,
  chat,
  faq,
  helpers
});

export default reducer;
