import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { logout } from 'store/auth/actions';
import { useEffect } from 'react';

// ==============================|| APP ||============================== //

const App = () => {
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);
  // useEffect(() => {
  //   const handleUnload = () => {
  //     dispatch(logout());
  //     console.log('Browser is closing or navigating away');
  //   };

  //   window.addEventListener('unload', handleUnload);

  //   return () => {
  //     window.removeEventListener('unload', handleUnload);
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleLogout = () => {
  //     dispatch(logout());
  //     console.log('Browser is closing or navigating away');
  //   };
  //   window.addEventListener('beforeunload', handleLogout);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleLogout);
  //   };
  // }, []);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
