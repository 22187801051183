import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import clipboardCopy from 'clipboard-copy';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { updateProfilePicute } from 'store/auth/actions';
import LinkIcon from '@mui/icons-material/Link';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { width } from '@mui/system';
import { useRef } from 'react';
import IdProofUpload from './IdProofUpload';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 310,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const UserProfile = () => {
  const dispatch = useDispatch();
  //   const baseUrl = 'http://localhost:3000';
  //   const baseUrl = 'http://65.0.170.77:3001';
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [image, setImage] = useState(null);
  const [imageData, setImageData] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [transShowPass, setTransShowPass] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openLinkSnack, setOpenLinkSnack] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [strProfileUrl, setStrProfileUrl] = useState('');

  //editIcon
  const [isEdit, setIsEdit] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setImageData('');
  };
  //functions
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickTransShowPassword = () => {
    setTransShowPass(!transShowPass);
  };
  const { user } = useSelector((state) => state?.auth);
  const formik = useFormik({
    initialValues: {
      strProfileBase64: '',
      strIDBase64: '',
      strTransProfBase64: '',
      strName: user?.strName ? user?.strName : '',
      strFullName: user?.strFullName ? user?.strFullName : '',
      strMobileNo: user?.strMobileNo ? user?.strMobileNo : '',
      strEmail: user?.strEmail ? user?.strEmail : '',
      strPassword: '',
      confirmPassword: '',
      strTransPassword: '',
      confirmTransPassword: '',
      strRefCodeBy: '',
      strRefCode: user?.strRefCode ? user?.strRefCode : '',
      strPackageId: '',
      intCapitalAmt: '',
      intTotalAmt: ''
      // submit: null
    },
    validationSchema: Yup.object().shape({
      strName: Yup.string().required('Username is required').nullable(),
      strFullName: Yup.string().required('Full Name is required').nullable(),
      strIDBase64: Yup.string().required('ID proof is required').nullable(),
      strMobileNo: Yup.string().matches('[0-9]{10}', 'Provide a valid phone number').max(10).min(10).required('Phone number is required'),
      strEmail: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      strPassword: Yup.string().max(255).required('Password is required'),
      confirmPassword: Yup.string()
        .required('Please retype your password.')
        .oneOf([Yup.ref('strPassword'), null], 'Passwords must match'),
      strTransPassword: Yup.string().max(255).required('Password is required'),
      confirmTransPassword: Yup.string()
        .required('Please retype your password.')
        .oneOf([Yup.ref('strTransPassword'), null], 'Passwords must match')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      values.strType = 'USER';

      setSubmitting(true);
    }
  });
  const [copyTitle, setCopyTitle] = useState('Copy');
  const [emailState, setEmailState] = useState(user?.strEmail);
  useEffect(() => {
    setEmailState(user?.strEmail);
  }, [user?.strEmail]);
  const encodeString = (string) => {
    return encodeURIComponent(string);
  };
  const shareLink = async (baseUrl, referralCode) => {
    const encodedReferralCode = encodeURIComponent(referralCode);
    const finalLink = `${baseUrl}/register?referral=${encodedReferralCode}`;
    clipboardCopy(finalLink);
    setOpenLinkSnack(true);
  };
  const copyToClipboard = (text) => {
    clipboardCopy(text);
    setOpen(true);
  };
  const handleCloseSnackLink = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenLinkSnack(false);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  console.log('user', user);
  const shareViaWhatsApp = (link, referralcode) => {
    const signupUrl = link;
    const referralCode = encodeURIComponent(referralcode);
    // const signupLink = `<a href="${signupUrl}" style="color: blue;">${signupUrl}</a>`;
    const whatsappMessage = `Join us! Sign up using my referral code: ${referralCode}\n\n${signupUrl}`;
    const whatsappLink = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;

    window.open(whatsappLink, '_blank');
  };
  const { errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values } = formik;
  const handleClickCameraIcon = () => {
    handleOpenModal();
  };
  const handleChangeImage = (e) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setStrProfileUrl(event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    setImage(e.target.files[0]);

    setImageData(URL.createObjectURL(e.target.files[0]));
  };

  const onClickSet = async () => {
    if (!strProfileUrl) {
      return;
    }
    const profileUrl = strProfileUrl.split(',')[1];
    await dispatch(updateProfilePicute({ strProfileBase64: profileUrl }, { strUserId: user._id }));
    setImageData('');
    handleCloseModal();
  };
  console.log('user', user);
  const handleClickEditIcon = () => {
    setIsEdit(!isEdit);
  };
  const handleClickUpdate = async () => {
    await dispatch(updateProfilePicute({ strEmail: emailState }, { strUserId: user._id }));
    setIsEdit(false);
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} position={'relative'}>
                <Grid container display={'flex'} justifyContent={'center'}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <IconButton onClick={handleClickCameraIcon} color="primary" aria-label="upload picture" component="label">
                        <Avatar style={{ backgroundColor: '#ffffff', border: '1px solid #1699D7' }}>
                          <PhotoCamera />
                        </Avatar>
                      </IconButton>
                    }
                  >
                    {/* <Avatar
                        alt="Remy Sharp"
                        src={logdata?.profilePicture?.key ? logdata?.profilePicture?.imageURL : ''}
                        sx={{ width: 150, height: 150 }}
                    /> */}
                    <Avatar alt="Remy Sharp" src={user?.strProfileUrl} sx={{ width: 170, height: 170 }}></Avatar>
                    {/* <div style={{ position: 'relative', width: '170px', height: '170px' }}> */}
                    {/* <Avatar alt="Remy Sharp" src={user?.strProfileUrl} sx={{ width: '100%', height: '100%' }} /> */}

                    {/* <Typography
                      variant="subtitle2"
                      color="primary"
                      back
                      sx={{
                        backgroundColor: 'green',
                        position: 'absolute',
                        left: '0%',
                        top: '50%',
                        transform: 'translate(-50%, -50%) rotate(-45deg)'
                      }}
                    >
                      Verified
                    </Typography> */}
                    {/* </div> */}
                  </Badge>
                </Grid>
                {user?.strKYCStatus === 'A' && (
                  <div style={{ position: 'absolute', left: '0%', top: '0%', width: '170px', height: '170px' }}>
                    <Chip
                      label="VERIFIED"
                      color="success"
                      sx={{
                        backgroundColor: 'green',
                        position: 'absolute',
                        left: '20%',
                        top: '20%',
                        transform: 'translate(-50%, -50%) rotate(-45deg)',
                        width: '100%'
                      }}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
                <Typography variant="h3">{user?.strFullName}</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={4} md={4}>
                    <Typography>Username </Typography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Typography>: {user?.strName}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container display={'flex'} alignItems={'center'}>
                  <Grid item xs={2} md={2}>
                    <Typography>Email </Typography>
                  </Grid>
                  <Grid item xs={2} md={2} display={'flex'} alignItems={'center'}>
                    <IconButton onClick={handleClickEditIcon}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                  {isEdit ? (
                    <Grid item xs={8} md={8}>
                      <TextField onChange={(e) => setEmailState(e.target.value)} value={emailState} fullWidth />
                    </Grid>
                  ) : (
                    <Grid item xs={8} md={8}>
                      <Typography>: {user?.strEmail}</Typography>
                    </Grid>
                  )}
                  {isEdit && <Grid item xs={4} md={4} sx={{ mt: '10px' }}></Grid>}
                  {isEdit && (
                    <Grid item xs={8} md={8} sx={{ mt: '10px' }}>
                      <Button onClick={handleClickUpdate} disabled={user?.strEmail === emailState} variant="contained">
                        Update
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={4} md={4}>
                    <Typography>Phone </Typography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Typography>: {user?.strMobileNo}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={4} md={4}>
                    <Typography>Refferred code </Typography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Typography>: {user?.strRefCodeBy}</Typography>
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={4} md={4}>
                    <Typography>Refferral Code </Typography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <Typography>: {user?.strRefCode}</Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Tooltip arrow title={copyTitle}>
                          <IconButton onClick={() => copyToClipboard(user?.strRefCode)}>
                            <ContentCopyIcon style={{ color: 'black' }} />
                          </IconButton>
                        </Tooltip>
                        <Snackbar
                          open={open}
                          autoHideDuration={3000}
                          onClose={handleClose}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Code Copied.
                          </Alert>
                        </Snackbar>
                        <Tooltip arrow title="Share">
                          <IconButton onClick={() => shareLink(baseUrl, user?.strRefCode)}>
                            <LinkIcon style={{ color: 'black' }} />
                          </IconButton>
                        </Tooltip>

                        <Snackbar
                          open={openLinkSnack}
                          autoHideDuration={3000}
                          onClose={handleCloseSnackLink}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                          <Alert variant="filled" onClose={handleCloseSnackLink} severity="success" sx={{ width: '100%' }}>
                            Link Copied.
                          </Alert>
                        </Snackbar>
                        <Tooltip arrow title="Share Via Whatsapp">
                          <IconButton
                            onClick={() => shareViaWhatsApp(`${baseUrl}/register?referral=${user?.strRefCode}`, user?.strRefCode)}
                          >
                            <WhatsAppIcon style={{ color: 'black' }} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={4} md={4}></Grid>
                  <Grid item xs={8} md={8}>
                    {/* {user?.strKYCStatus === 'A' ? (
                      <Chip icon={<VerifiedUserIcon />} label="KYC verified" color="success" />
                    ) : (
                      <Chip label="KYC Verification pending" color="warning" />
                    )} */}
                    {user?.strKYCStatus === 'NA' && <Chip color="warning" label="KYC Verification pending" />}
                    {user?.strKYCStatus === 'R' && <Chip color="error" label="KYC Verification failed" />}
                  </Grid>
                </Grid>
              </Grid>
              {user?.strKYCStatus === 'R' && (
                <Grid item xs={12} md={12}>
                  <IdProofUpload />
                </Grid>
              )}
            </Grid>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Grid container>
            <Box sx={style}>
              <Grid container>
                <Grid item md={12} xs={12} sm={12}>
                  <Avatar alt="" src={imageData ? imageData : user?.strProfileUrl} sx={{ width: 250, height: 250 }} />
                </Grid>
                <Grid item md={12} xs={12} sm={12} sx={{ marginTop: '10px' }}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={6} sm={6}>
                      <Button variant="contained" component="label">
                        Choose File
                        <input onChange={handleChangeImage} hidden accept="image/*" multiple type="file" />
                      </Button>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                      <Button variant="contained" onClick={onClickSet}>
                        Set
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                {/* <img alt="" src={imageData} /> */}
              </Grid>
            </Box>
          </Grid>
        </Modal>
      </Grid>
      {/* <MainCard title="User Profile">
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={matchDownSM ? 1.5 : 2}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={matchDownSM ? 1.5 : 2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        size={matchDownSM ? 'small' : 'normal'}
                        label="Username"
                        value={values.strName}
                        name="strName"
                        type="text"
                        onChange={handleChange}
                        error={Boolean(touched.strName && errors.strName)}
                      />
                      {touched.strName && errors.strName && (
                        <FormHelperText error id="standard-weight-helper-text-password-register">
                          {errors.strName}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        size={matchDownSM ? 'small' : 'normal'}
                        label="Full Name"
                        value={values?.strFullName}
                        name="strFullName"
                        type="text"
                        onChange={handleChange}
                        error={Boolean(touched.strFullName && errors.strFullName)}
                      />
                      {touched.strFullName && errors.strFullName && (
                        <FormHelperText error id="standard-weight-helper-text-password-register">
                          {errors.strFullName}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size={matchDownSM ? 'small' : 'normal'}
                    label="Phone Number"
                    value={values.strMobileNo}
                    name="strMobileNo"
                    type="text"
                    onChange={handleChange}
                    error={Boolean(touched.strMobileNo && errors.strMobileNo)}
                  />
                  {touched.strMobileNo && errors.strMobileNo && (
                    <FormHelperText error id="standard-weight-helper-text-password-register">
                      {errors.strMobileNo}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth error={Boolean(touched.strEmail && errors.strEmail)}>
                  <InputLabel htmlFor="outlined-adornment-email-register">Email Address</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-register"
                    type="email"
                    label="Email Address"
                    size={matchDownSM ? 'small' : 'normal'}
                    value={values.strEmail}
                    name="strEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.strEmail && errors.strEmail && (
                    <FormHelperText error id="standard-weight-helper-text--register">
                      {errors.strEmail}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth error={Boolean(touched.strEmail && errors.strEmail)}>
                  <InputLabel htmlFor="outlined-adornment-email-register">Referral Code</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-register"
                    type="email"
                    label="Referral Code"
                    size={matchDownSM ? 'small' : 'normal'}
                    value={values.strRefCode}
                    name="strEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.strEmail && errors.strEmail && (
                    <FormHelperText error id="standard-weight-helper-text--register">
                      {errors.strEmail}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid> */}

      {/* <Grid item xs={12} md={12}>
              {profileFileName ? <Typography>{profileFileName}</Typography> : <Typography>Choose Profile Picture</Typography>}
            </Grid>
            <Grid item xs={12} md={12}>
              {profileFileName ? (
                <Button fullWidth variant="contained" onClick={handleClickFileRemove}>
                  Remove
                </Button>
              ) : (
                <Button fullWidth variant="contained" component="label">
                  Upload
                  <input onChange={handleChangeFileProfile} hidden accept="image/*" type="file" />
                </Button>
              )}
              {errors.strProfileBase64 && (
                <FormHelperText error id="standard-weight-helper-text-password-register">
                  {errors.strProfileBase64}
                </FormHelperText>
              )}
            </Grid> */}

      {/* <Grid item xs={12} md={12}>
              {matchDownSM ? (
                <div
                  style={{
                    overflowX: 'scroll'
                  }}
                >
                  <Box style={{ display: 'flex', maxWidth: '200px' }}>
                    {activePackages?.map((packageItem, i) => (
                      <Grid key={packageItem?._id} sx={{ marginRight: '10px' }}>
                        <Paper elevation={4}>
                          <Card
                            onClick={() => handleClickCardMob(packageItem, i)}
                            sx={{
                              height: '100px',
                              width: '200px',
                              backgroundColor: activeIndex === i ? '#111936' : '#D1C4E9',
                              color: activeIndex === i ? '#D1C4E9' : '#111936',
                              padding: '16px'
                            }}
                          >
                            <Grid container>
                              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                <Typography sx={{ fontSize: '1.5rem' }}>{packageItem?.strName}</Typography>
                              </Grid>
                              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                <Typography sx={{ fontSize: '.9rem' }}>{packageItem?.strPercentageRane}%</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography sx={{ fontSize: '1.1rem' }}>${packageItem?.intDepositeAmount}</Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        </Paper>
                      </Grid>
                    ))}
                  </Box>
                </div>
              ) : (
                <Grid container spacing={1}>
                  {activePackages?.map((packageItem, i) => (
                    <Grid item md={6} key={packageItem?._id}>
                      <Card
                        onClick={() => handleClickCardMob(packageItem, i)}
                        sx={{
                          height: '100px',
                          width: '100%',
                          backgroundColor: activeIndex === i ? '#212946' : '#D1C4E9',
                          color: activeIndex === i ? '#D1C4E9' : '#212946',
                          padding: '16px'
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                            <Typography sx={{ fontSize: '1.5rem' }}>{packageItem?.strName}</Typography>
                          </Grid>
                          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                            <Typography sx={{ fontSize: '.9rem' }}>{packageItem?.strPercentageRane}%</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ fontSize: '1.1rem' }}>${packageItem?.intDepositeAmount}</Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
            {selectedPachageData && (
              <Grid item xs={12} md={12}>
                <PackageDetailCard packageData={selectedPachageData} />
              </Grid>
            )} */}
      {/* 
            <Grid item xs={12} md={12}>
              <FormControl fullWidth error={Boolean(touched.strPassword && errors.strPassword)}>
                <InputLabel htmlFor="outlined-adornment-password-register">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password-register"
                  type={showPassword ? 'text' : 'password'}
                  size={matchDownSM ? 'small' : 'normal'}
                  value={values.strPassword}
                  name="strPassword"
                  label="Password"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end" size="large">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{}}
                />
                {touched.strPassword && errors.strPassword && (
                  <FormHelperText error id="standard-weight-helper-text-password-register">
                    {errors.strPassword}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl fullWidth error={Boolean(touched.strTransPassword && errors.strTransPassword)}>
                <InputLabel htmlFor="outlined-adornment-password-register">Transaction Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password-register"
                  type={transShowPass ? 'text' : 'password'}
                  size={matchDownSM ? 'small' : 'normal'}
                  value={values.strTransPassword}
                  name="strTransPassword"
                  label="Transaction Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickTransShowPassword} edge="end" size="large">
                        {transShowPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{}}
                />
                {touched.strTransPassword && errors.strTransPassword && (
                  <FormHelperText error id="standard-weight-helper-text-password-register">
                    {errors.strTransPassword}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid> */}

      {/* <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label="Referance Code"
                  size={matchDownSM ? 'small' : 'normal'}
                  name="strRefCodeBy"
                  type="text"
                  onChange={handleChange}
                />
              </FormControl>
            </Grid> */}

      {/* <Grid item xs={12} md={12}>
              <Box sx={{ mt: 2 }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    Update
                  </Button>
                </AnimateButton>
              </Box>
            </Grid> */}
      {/* </Grid> */}
      {/* //       </Form> */}
      {/* //     </FormikProvider> */}
      {/* //     <button onClick={()=>shareLink()}>Share Link</button>
    //     <button onClick={() => copyToClipboard('textTosaasCopy')}>Copy Text</button>
    //   </MainCard> */}
    </>
  );
};

export default UserProfile;
