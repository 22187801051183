import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
// import bgImg from 'assets/images/homepage/IMG_8511.JPG';
import bgImg from 'assets/images/homepage/bgImage.JPG';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from 'react';
import CustomDelayAnimation from 'ui-component/animations/CustomDelayAnimation';
import RoyalPrimeFxTitle from 'ui-component/animations/RoyalPrimeFxTitle';
import CustomHideAnimation from 'ui-component/animations/CustomHideAnimation';
import FromLeftAnim from 'ui-component/animations/FromLeftAnim';
import CustomDelayAnimWithX from 'ui-component/animations/CustomDelayAnimWithX';

const MainContent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [bool, setBool] = useState(false);

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const handleClickSignUp = () => {
    navigate('/register');
  };
  const handleClickLogin = () => {
    navigate('/login');
  };
  return (
    <Grid
      container
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgImg})`,
        // backgroundImage: `url(${bgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      {/* <CustomHideAnimation delayInSec={2.5} durInSec={1}>
        <RoyalPrimeFxTitle />
      </CustomHideAnimation> */}
      <CustomDelayAnimation delayInSec={1} durInSec={1}>
        <Grid container>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={10} md={10}>
            <Grid container>
              <Grid sx={{ height: matchDownSM ? '100px' : '250px' }} item xs={12} md={12}></Grid>
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={12} md={12} justifyContent={'center'}>
                        <Typography sx={{ fontSize: '20px', color: 'white', textAlign: 'center' }} variant="h4">
                          Welcome to
                        </Typography>
                        <Typography sx={{ fontSize: '40px', color: '#FEC260', textAlign: 'center', marginTop: '12px' }} variant="h1">
                          Royal Prime Fx
                        </Typography>
                        {/* <Typography sx={{ fontSize: '20px', color: 'white' }} variant="h4">
                          Unlock new opportunites
                        </Typography> */}
                      </Grid>
                    </Grid>
                  </Grid>
                  {matchDownSM && (
                    <Grid item xs={12} mt={10}>
                      <CustomDelayAnimWithX delayInSec={2} durInSec={1} value={-150}>
                        <Typography sx={{ fontSize: '20px', color: 'white', textAlign: 'center', fontWeight: 500 }}>
                          <span style={{ fontSize: '30px', color: '#FFD369', fontWeight: 700 }}>Empowering</span> Future
                        </Typography>
                      </CustomDelayAnimWithX>
                      <CustomDelayAnimWithX delayInSec={2} durInSec={1} value={150}>
                        <Typography sx={{ fontSize: '30px', color: '#FFD369', textAlign: 'center', fontWeight: 700 }}>
                          <span style={{ fontSize: '20px', color: 'white', fontWeight: 500 }}>Unleashing</span> Innovation
                        </Typography>
                      </CustomDelayAnimWithX>
                      <CustomDelayAnimWithX delayInSec={2} durInSec={1} value={-150}>
                        <Typography sx={{ fontSize: '20px', color: 'white', textAlign: 'center', fontWeight: 500 }}>
                          <span style={{ fontSize: '30px', color: '#FFD369', fontWeight: 700 }}>Drive</span> Success
                        </Typography>
                      </CustomDelayAnimWithX>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6} sx={{ marginTop: matchDownSM ? '50px' : '0px' }}>
                    <Grid
                      sx={{ height: '100%' }}
                      container
                      display={'flex'}
                      justifyContent={matchDownSM ? 'center' : 'center'}
                      alignItems={'center'}
                    >
                      <Button
                        onClick={handleClickSignUp}
                        style={{
                          backgroundColor: '#FEC260',
                          color: 'black',
                          marginRight: '16px',
                          borderRadius: '25px',
                          border: 'solid 3px white'
                        }}
                        variant="contained"
                      >
                        Sign Up
                      </Button>
                      <Button
                        onClick={handleClickLogin}
                        style={{ border: 'solid 3px white', borderRadius: '25px', backgroundColor: '#030708' }}
                        variant="contained"
                      >
                        Member Login
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box
                // className={style.explore}
                onClick={() => setBool(!bool)}
                mx={1}
                mt={8}
                alignItems="center"
                sx={{
                  display: 'flex',
                  cursor: 'pointer',
                  bgcolor: '',
                  animation: 'anim 2s linear infinite',
                  '@keyframes anim': {
                    '0%': {
                      transform: 'scale(1)'
                    },
                    '50%': {
                      transform: 'scale(1.1)'
                    },
                    '100%': {
                      transform: 'scale(1)'
                    }
                  }
                }}
              >
                {/* <Space wrap>
                        <Popover content={content} trigger="click">
                            <Typography variant='h5' sx={{ color: "white", mr: 2, fontWeight: "bold" }}>Explore Realverse

                                {[1, 2, 3, 4,].map(item =>
                                    <RightOutlined />

                                )}
                            </Typography>
                        </Popover>
                    </Space> */}

                <Typography variant="h5" sx={{ color: 'white', mr: 2, fontWeight: 'bold' }}>
                  Explore Royal Prime Fx
                </Typography>

                {[1, 2, 3, 4].map((item, i) => (
                  <ArrowForwardIosIcon key={i} sx={{ color: 'white' }} />
                ))}
              </Box>
              {bool && (
                <Grid
                  onClick={() => setBool(!bool)}
                  container
                  md={8}
                  sx={{
                    height: '100vh',
                    // bgcolor: "rgba(0, 0, 0, 0.6)",
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 100,
                    p: 1,
                    ml: { md: 50 }
                  }}
                >
                  <Grid
                    container
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50% , -50% ) '
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        m: 1.5,
                        p: 1,
                        bgcolor: 'black',
                        border: 'solid 3px #E2FFD3',
                        borderRadius: '10px'
                      }}
                    >
                      <Grid container sx={{ position: 'relative', top: '0', left: '0', my: 1 }}>
                        <img width="100%" src="/assets/banner/realverse.jpg" alt="" />

                        <Typography
                          variant="h4"
                          sx={{
                            position: 'absolute',
                            bottom: 10,
                            right: 10
                          }}
                          color="white"
                          fontWeight="bold"
                        >
                          Realverse
                        </Typography>
                      </Grid>

                      <Typography sx={{ my: 1, color: '#E2FFD3' }}>
                        Blockchain technology plays a significant role in virtual real estate, especially in Realverse. In these virtual
                        environments, users can create and monetize virtual properties in ways similar to real-world real estate. They can
                        develop and build on their virtual land, offer virtual services, host events, or create art installations. The value
                        of virtual real estate can fluctuate based on factors such as location, accessibility, popularity, and the demand
                        for specific digital assets
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid sx={{ height: matchDownSM ? '200px' : '250px' }} item xs={12} md={12}></Grid>
            </Grid>
          </Grid>

          <Grid item xs={1} md={1}></Grid>
        </Grid>
      </CustomDelayAnimation>
    </Grid>
  );
};

export default MainContent;
