import {
  Alert,
  Button,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { getWithdrawals } from 'store/withdraw/actions';

const Withdraw = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const [withdrawalStatus, setWithdrawalStatus] = useState('P');
  const handleChange = (event, newAlignment) => {
    setWithdrawalStatus(newAlignment);
  };
  useEffect(() => {
    dispatch(getWithdrawals({ chrStatus: withdrawalStatus }));
  }, [withdrawalStatus]);
  const { withdrawalList } = useSelector((state) => state?.withdraw);
  const handleClickWithdrawal = (id) => {
    // navigate(`/user/withdraw/detailedwithdrawal/${id}`);
  };
  const handleClickWithdraw = () => {
    navigate('/user/withdraw/newwithdraw');
  };
  console.log('withdrawalList', withdrawalList);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Applied Withdrawals</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} display={'flex'} justifyContent={'right'}>
        <Button variant="contained" onClick={handleClickWithdraw}>
          Withdraw
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          <ToggleButtonGroup
            sx={{ backgroundColor: 'white' }}
            color="primary"
            value={withdrawalStatus}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="P">Pending</ToggleButton>
            <ToggleButton value="N">Approved</ToggleButton>
            <ToggleButton value="R">Rejected</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      {matchDownSM && (
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            {withdrawalList?.map((withdrawal, i) => (
              <Grid item xs={12} key={i}>
                <Card onClick={() => handleClickWithdrawal(withdrawal?._id)}>
                  <Grid container p={2}>
                    <Grid item xs={10}>
                      <Typography>Name : {withdrawal?.objUser?.strFullName}</Typography>
                      <Typography>Type : {withdrawal?.strType}</Typography>
                      <Typography>Amount : {withdrawal?.intPayableAmt}</Typography>
                      {withdrawal?.strRejectMsg && <Typography>Reason : {withdrawal?.strRejectMsg}</Typography>}
                    </Grid>
                    <Grid item xs={2} display={'flex'} alignItems={'center'}>
                      {/* <IconButton>
                        <ArrowForwardIcon style={{ color: 'white' }} />
                      </IconButton> */}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {!matchDownSM && (
        <Grid item xs={12} md={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Amount</TableCell>
                  {/* <TableCell>Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {withdrawalList?.map((withdrawal, i) => (
                  <TableRow key={i}>
                    <TableCell>{withdrawal?.objUser[0]?.strFullName}</TableCell>
                    <TableCell>{withdrawal?.strType}</TableCell>
                    <TableCell>{withdrawal?.intPayableAmt}</TableCell>
                    {/* <TableCell>
                      <Button onClick={() => handleClickWithdrawal(withdrawal?._id)} variant="contained">
                        View
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      {withdrawalList?.length === 0 && (
        <Grid item xs={12} md={12}>
          <Alert severity="info">No datas found</Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default Withdraw;
