import { CURRENT_ASSOCIATES_REFCODE } from './constants';

const initialState = {
  currentRefCodes: null
};

export default function helpers(state = initialState, { type, payload }) {
  switch (type) {
    case CURRENT_ASSOCIATES_REFCODE:
      return {
        ...state,
        currentRefCodes: payload
      };
    default:
      return state;
  }
}
