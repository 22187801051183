import { Card, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSettings } from 'store/settings/actions';
import { getWalletDetails } from 'store/wallet/actions';
import api from 'utils/api';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MainCard from 'ui-component/cards/MainCard';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: '50%',
    top: -30,
    right: -180
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
    borderRadius: '50%',
    top: -160,
    right: -130
  }
}));

const AssociatesMain = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [cardsLength, setCardsLength] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [associatesArr, setAssociatesArr] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  useEffect(() => {
    dispatch(getWalletDetails({ _id: user._id }));
  }, [user]);
  const { walletDetails } = useSelector((state) => state?.wallet);
  const capitalAmount = walletDetails?.arrCapitals[0]?.intAmt ? walletDetails?.arrCapitals[0]?.intAmt : 0;
  useEffect(() => {
    dispatch(getSettings({ strType: 'settings', type: 'commonList' }));
  }, []);
  const { settingsValues } = useSelector((state) => state?.settings);
  console.log('settingsValues', settingsValues);
  useEffect(() => {
    if (capitalAmount && settingsValues) {
      if (capitalAmount > settingsValues[4]?.intInvestmentLimit) {
        setCardsLength(5);
      }
      if (capitalAmount > settingsValues[9]?.intInvestmentLimit) {
        setCardsLength(7);
      }
      if (capitalAmount > settingsValues[5]?.intInvestmentLimit) {
        setCardsLength(10);
      }
    }
  }, [capitalAmount, settingsValues]);
  useEffect(() => {
    const fetchData = async (cardLength, refCode) => {
      try {
        setIsLoading(true);
        const { data } = await api.post('/get_associates', { intLoopValue: cardLength, strRefCode: refCode });
        console.log('data', data);
        setAssociatesArr(data.arrList);
        setIsLoading(false);
      } catch (error) {
        console.log('error', error);
        setIsLoading(false);
      }
    };
    if (cardsLength && user?.strRefCode) {
      fetchData(cardsLength, user?.strRefCode);
    }
  }, [cardsLength, user]);
  const handleClickCard = async (item) => {
    console.log(item);
    await localStorage.setItem('currentAssociates', JSON.stringify(item));
    // dispatch({
    //   type: CURRENT_ASSOCIATES_REFCODE,
    //   payload: item
    // });
    navigate('/user/associates/users');
  };
  if (isLoading) {
    return (
      <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card variant="outlined" style={{ padding: '16px' }}>
          <Typography variant="h3">Associates</Typography>
        </Card>
      </Grid>
      {associatesArr.length &&
        associatesArr?.map((item, i) => (
          <Grid key={i} item xs={12}>
            <CardWrapper sx={{ padding: 2 }} border={false} content={false} onClick={() => handleClickCard(item)}>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="h4">Level {item.level}</Typography>
                  <Typography>No of users {item.arrRefCodes.length}</Typography>
                </Grid>
                <Grid item xs={2} display={'flex'} alignItems={'center'}>
                  <IconButton>
                    <ArrowForwardIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </CardWrapper>
          </Grid>
        ))}
    </Grid>
  );
};

export default AssociatesMain;
