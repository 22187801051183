import { Grid, Typography } from '@mui/material';
import React from 'react';
import { SwiperSlide } from 'swiper/react';
import SwiperComponent from 'ui-component/swiper/Swiper';
import decentralizationImg from 'assets/images/homepage/IMG_0300.JPG';
import borderlessImg from 'assets/images/homepage/IMG_0301.JPG';
import globalImg from 'assets/images/homepage/IMG_0302.JPG';
import fastImg from 'assets/images/homepage/IMG_0303.JPG';
import metaverseImg from 'assets/images/homepage/IMG_0304.JPG';
import stakingImg from 'assets/images/homepage/IMG_0306.JPG';

const SlideCards = () => {
  const list = [
    {
      img: decentralizationImg,
      title: 'Decentralization',
      content: `Distribution of power, control and decision making away from a central authority`
    },
    {
      img: borderlessImg,
      title: 'Borderless',
      content: `virtual world or digital space that transcends physical boundaries`
    },
    {
      img: metaverseImg,
      title: 'Metaverse Shopping',
      content: `Digital shopping using virtual reality in real time`
    },
    {
      img: stakingImg,
      title: 'Staking',
      content: `Blockchain network locking up and holding`
    },
    {
      img: globalImg,
      title: 'Global Opportunity',
      content: `Merchants involve access vast and diverse customer base worldwide`
    },
    {
      img: fastImg,
      title: 'Fast and transparent',
      content: `Operates quickly and openly without hidden or obscured elements`
    }
  ];
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        py: 9,
        // bgcolor: 'white',
        height: { xs: 'fit-contnent' },
        backgroundImage: 'url(/assets/banner/future.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <Typography
        variant="h1"
        sx={{
          width: '100%',
          fontWeight: 'bold',
          color: 'white',
          textAlign: 'center',
          // borderBottom: "2px solid black",
          mb: 5
        }}
      >
        Explore Metaverse
      </Typography>

      <Grid lg={12} container>
        <SwiperComponent>
          {list.map((data) => (
            <SwiperSlide key={data.title}>
              <Grid sx={{ bgcolor: '#E2FFD3', m: 1, p: 1 }}>
                <Grid container sx={{ position: 'relative' }}>
                  <img width="100%" height={'250px'} src={data.img} alt="" />

                  {/* <Typography
                    variant="h5"
                    sx={{
                      position: 'absolute',
                      bottom: 10,
                      right: 10,
                      color: 'white',
                      fontWeight: 'bold',
                      my: 1
                    }}
                  >
                    {data.title}
                  </Typography> */}
                </Grid>

                {/* <Typography
                  variant="subtitle1"
                  sx={{
                    width: '100%',
                    height: { xs: '60px', sm: '80px', md: '90px' },
                    bgcolor: 'white',
                    color: 'black',
                    fontWeight: 'normal',
                    my: 1
                  }}
                >
                  {data.content}
                </Typography> */}
              </Grid>
            </SwiperSlide>
          ))}
        </SwiperComponent>
      </Grid>
    </Grid>
  );
};

export default SlideCards;
