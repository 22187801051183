import { GET_INVESTMENTS } from './constants';

const initialState = {
  myInvestments: []
};

export default function investments(state = initialState, { type, payload }) {
  switch (type) {
    case GET_INVESTMENTS:
      return {
        ...state,
        myInvestments: payload
      };
    default:
      return state;
  }
}
