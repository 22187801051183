import { Card, Grid, Typography } from '@mui/material';
import React from 'react';

const ComposeMail = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h3">Mail</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card sx={{ padding: '16px' }}>
          <Typography variant="h5">
            Kindly mail your queries to <a href="#">royalprimefx@gmail.com</a>
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ComposeMail;
