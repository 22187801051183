import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ForgotSendOTP from './ForgotSendOTP';
import VerifyOTPForgot from './VerifyOTPForgot';
import ChangePassword from './ChangePassword';
import { useState } from 'react';
import PasswordUpdateSuccessful from './PasswordUpdateSuccessful';
import ForgotTransSendOTP from './ForgotTransSendOTP';
import VerifyTransOTPForgot from './VerifyTransOTPForgot';
import ChangeTransPassword from './ChangeTransPassword';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  '@media (max-width: 600px)': {
    width: '90%'
  },
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '20px',
  boxShadow: 24,
  p: 5
};

const ForgotTransPasswordModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [indexValue, setIndexValue] = useState(0);
  const [userId, setUserId] = useState('');
  const [token, setToken] = useState('');

  const handleChangeUIs = (i) => {
    console.log('i', i);
    switch (i) {
      case 0:
        return <ForgotTransSendOTP setIndexValue={setIndexValue} setUserId={setUserId} />;
      case 1:
        return <VerifyTransOTPForgot userId={userId} setIndexValue={setIndexValue} setToken={setToken} />;
      case 2:
        return <ChangeTransPassword token={token} setIndexValue={setIndexValue} />;
      case 3:
        return <PasswordUpdateSuccessful onClose={onClose} />;
      default:
        return <ForgotTransSendOTP />;
    }
  };
  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>{handleChangeUIs(indexValue)}</Box>
    </Modal>
  );
};

export default ForgotTransPasswordModal;
