import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { handleToken } from 'utils/jwt';
import { LOGIN_SUCCESS } from 'store/auth/constants';
import { useState } from 'react';
import { getUserById } from 'store/auth/actions';

const PrivateRoute = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  console.log(pathname);
  const { isLoggedIn } = useSelector((state) => state?.auth);
  const isLogged = localStorage.getItem('isAuthenticate');
  console.log(isLoggedIn);
  const userDetail = JSON.parse(localStorage.getItem('user'));
  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticate = localStorage.getItem('isAuthenticate');
      if (isAuthenticate && userDetail && accessToken) {
        await handleToken(accessToken);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            loginSuccess: true,
            userDetails: userDetail
          }
        });
        dispatch(getUserById({ strUserId: userDetail?._id }));
      }
    };
    checkAuthentication();
  }, [isLoggedIn]);
  if (!isLogged) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

PrivateRoute.propTypes = {
  children: PropTypes.node
};

export default PrivateRoute;
